import React, {useMemo, useState} from "react";

import {MaxBtn} from "../core/components";
import {LightContainer, MyModal, MyReactTable} from "../core/input_fields";
import {maxiGet, maxiPost} from "../core/maxios";
import Status from "../core/status";
import TeamEdit from "./teamEdit";


export default function TeamList({persons, nameCell}) {
    const [teams, setTeams] = useState([]);
    const [{error, loading, success}, setStatusVar] = useState({loading: true});
    const loadTeams = () => {
        maxiGet("/team", {setStatusVar}).then(data => {
            setTeams(data.teams)
        })
    }
    useMemo(loadTeams, []);
    return <LightContainer name={"Teams"}>
        <div style={{float: "right", marginTop: "-60px"}}><MyModal onClose={loadTeams} trigger={<MaxBtn>Neue Mannschaft erstellen</MaxBtn>}>
            {close => <TeamEdit close={close} team={{}}  reload={loadTeams}/>}
        </MyModal></div>
        <Status type={"error"} text={error}/>
        <MyReactTable
            data={teams}
            loading={loading}
            columns={[
                {
                    Header: "Name",
                    accessor: "name",
                    filterable: true,
                    Cell: nameCell || (({original, value}) => value),
                },
                {
                    Header: "Anzahl",
                    accessor: "count",
                },
                {
                    maxWidth: 250,
                    Cell: ({original, value}) => <><MyModal onClose={loadTeams} trigger={<em>bearbeiten</em>}>
                        {close => <TeamEdit close={close} team={original} reload={loadTeams}/>}
                    </MyModal>
                        <em onClick={() => {
                            if (window.confirm("Team überall löschen?")) {
                                maxiPost("/team/hide", {ID: original.ID}).then(loadTeams)
                            }
                        }
                        }>löschen</em>
                    </>
                }
            ]}
        />
    </LightContainer>
}

