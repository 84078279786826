import React, {useContext, useMemo, useState} from "react";

import {Container, MaxLink} from "../core/components";
import {ButtonSelect, EnumInput, InputContainer, MyReactTable} from "../core/input_fields";
import {maxiGet} from "../core/maxios";
import Status from "../core/status";
import {UserContext} from "../user/UserContext";
import "./style.sass"
import {Link} from "react-router-dom";
import {dateFormatInline} from "../core/dateFuncs";
import {FormContextWrapper} from "../core/form_context";
import {EventKind, LicenceKind, SectorType, SectorTypeLicence} from "../core/enums";
import {encodeGetParams} from "../core/helpers";


const dateformatterMonthYear = new Intl.DateTimeFormat('de-AT', {
    year: 'numeric',
    month: 'short',
});


export default function EventList({location}) {
    const ausFortbildungen = location.pathname === "/aus-fortbildungen";
    const [events, setEvents] = useState([]);
    const [{error, loading, success}, setStatusVar] = useState({loading: true});
    const [state, setState] = useState({filter: {kind: "beides", sector: {label: "alle", value: "alle"}, forSectors: {label: "alle", value: "alle"}, forLicence: "alle",}})
    const user = useContext(UserContext)?.user || {}
    const loadEvents = () => {
        maxiGet("/event?" + encodeGetParams({ausFortbildungen}), {setStatusVar}).then(data => {
            const eventsParsed = data.events.map(a => ({...a, eventStart: new Date(a.eventStart), eventEnd: new Date(a.eventEnd), forSectors: a.forSectors.split(",")}))
            const now=new Date()
            //adding the event headings
            let eventsWithHeadings = [];
            let archiveReached=false;
            eventsParsed.forEach((event, i) => {
                if (!archiveReached && event.eventEnd<now){
                    eventsWithHeadings.push({
                        name: "Archiv",
                        heading: true,
                    })
                    archiveReached=true
                }
                if (eventsParsed[i - 1]?.eventStart?.getMonth() !== event.eventStart?.getMonth() && event.eventStart.getTime()) {
                    eventsWithHeadings.push({
                        name: dateformatterMonthYear.format(event.eventStart),
                        heading: true,
                    })
                }

                eventsWithHeadings.push(event)
            })
            setEvents(eventsWithHeadings)
        })
    }
    useMemo(loadEvents, []);


    return <Container  ID={"veranstaltungen"}>
        {
            user.role === 100 &&
            <div style={{float: "right"}}>
                <MaxLink to={"/veranstaltungen/neu"}>Veranstaltung hinzufügen</MaxLink>
            </div>
        }
        <h1>{ausFortbildungen ? "Aus-/Fortbildungen" : "Veranstaltungen"}</h1>
        {
            ausFortbildungen && <FormContextWrapper value={{state, setState}}>
                <div style={{marginBottom: "5px"}}>
                    <ButtonSelect selectives={[{value: "beides", label: "alle"}, {"value": "ausbildung", "label": "Ausbildungen"}, {"value": "fortbildung", "label": "Fortbildungen"}]} tag={"filter_kind"}/>
                </div>
                {
                    state.filter.kind === "ausbildung" && <><ButtonSelect selectives={[{value: "alle", label: "alle"}, ...LicenceKind]} tag={"filter_forLicence"}/><br/></>
                }

                {
                    Object.entries({
                        sector: "von Sparte",
                        forSectors: "für Sparte",
                    }).map(([f, fieldName], i) => {
                        return <InputContainer>

                            <EnumInput name={fieldName} tag={"filter_" + f} type="reactselect"
                                       selectives={{sector: [{value: "alle", label: "alle"}, ...SectorType], forSectors: [{value: "alle", label: "alle"}, ...SectorTypeLicence], kind: EventKind.slice(0, 2)}[f]}
                                       multiple={{forSectors: false}[f] ?? false}
                            />
                        </InputContainer>
                    })}
            </FormContextWrapper>
        }
        <Status type={"error"} text={error}/>
        <MyReactTable
            data={events.filter(event => {
                return !ausFortbildungen || event.heading || (
                    (!state.filter?.kind || state.filter?.kind === "beides" || event.kind?.value === state.filter?.kind) &&
                    (!state.filter?.forLicence || state.filter?.forLicence === "alle" || event.forLicence?.includes(state.filter?.forLicence)) &&
                    (!state.filter?.sector || state.filter?.sector?.value === "alle" || event.sector?.value === state.filter?.sector?.value) &&
                    (!state.filter?.forSectors || state.filter?.forSectors?.value === "alle" || event.forSectors.includes(state.filter?.forSectors?.value))
                )
            })}
            loading={loading}
            pageSizeDef={400}
            getTrProps={(state, rowinfo) => {
                return {className: rowinfo?.original?.heading ? "dividerRow" : ""}
            }}
            columns={[
                {
                    Header: "Datum",
                    accessor: "eventStart",
                    sortable: false,
                    maxWidth: window.innerWidth > 600?230:115,
                    Cell: ({value, original}) => original.heading ? <h4>{original.name}</h4> : <>{dateFormatInline(value)}{window.innerWidth > 600  && original.eventEnd.getDay()!==original.eventStart.getDay() && <> - {dateFormatInline(original.eventEnd)}</>}</>,
                },
                {
                    Header: "Veranstaltung",
                    filterable: true,
                    accessor: "name",
                    sortable: false,
                    filterMethod: (filter, row) => {
                        return row[filter.id].toLowerCase().indexOf(filter.value.toLowerCase()) > -1
                    },
                    Cell: ({value, original}) => !original.heading && <Link to={`/veranstaltungen/${original.ID}`}>{value}</Link>,
                },
                {
                    Header: "Sparte",
                    maxWidth: 150,
                    filterable: true,
                    accessor: "sector.label",
                    show: window.innerWidth > 600,
                },
                {
                    Header: "Ort",
                    filterable: true,
                    accessor: "city",
                    sortable: false,
                    maxWidth: 100,
                    show: window.innerWidth > 1000,
                }
            ]}
        />
    </Container>
}

