import React, {useContext, useState} from "react";

import {Loader, MaxBtn} from "../core/components";
import {LightContainer, TextfieldInput} from "../core/input_fields";
import {FormContextWrapper} from "../core/form_context";
import {maxiPost} from "../core/maxios";
import Status from "../core/status";
import UserHistory from "../user/userHistory";
import "./style.sass"
import ChangeAddressExt from "../user/addressExt";
import {UserContext} from "../user/UserContext";

const fields = {
    recipient: "Name oder Verein (Zeile 1)",
    recipientAddition: "Verein oder Verein (Zeile 2)",
    zvr: "ZVR Nummer",
    // country: "Land",
};


export default function BillingAddressEdit({billingAddress, reload, close}) {
    const [{error, loading, success}, setStatusVar] = useState({});
    const [state, setState] = useState({billingAddress})
    const user = (useContext(UserContext) || {}).user || {}
    const handleSubmit = () => {
        !loading && maxiPost("/billing_address", state.billingAddress, {setStatusVar})
            .then(() => {
                reload()
                close()
            })
    }

    return <LightContainer name={"Rechnungsadresse bearbeiten"}>
        <Status type={"error"} text={error}/>

        <FormContextWrapper value={{state, setState}} onSubmit={handleSubmit}>
            {Object.entries(fields).map(([f, fieldName], i) => {
                switch (f) {
                    default:
                        return <>
                            <TextfieldInput class={"form-control "} style={{width: "480px"}} name={fieldName + (f === "recipient" ? "*" : "")} tag={"billingAddress_" + f}/>
                            <br/>
                        </>;
                }

            })}
        </FormContextWrapper>

        {/* change address needs to be outside of FormContextWrapper as it interferes */}
        <ChangeAddressExt child={billingAddress} required changeAddress={a => {
            setState(old => ({billingAddress: {...old.billingAddress, ...a}}))
        }}/>
        <div>
            <br/>
            <MaxBtn onClick={handleSubmit}>Speichern</MaxBtn><Loader loading={loading}/>
            <br/>
            {
                billingAddress.ID && user.role > 80 && <LightContainer name={"Veränderungen"}>
                    <UserHistory userID={billingAddress.ID} personType={"billingAddress"}/>
                </LightContainer>
            }
        </div>
    </LightContainer>


}
