import PersonList from "./personList";
import TeamList from "./teamList";
import React, {useContext, useMemo, useState} from "react";
import {maxiGet} from "../core/maxios";
import {Container} from "../core/components";
import {UserContext} from "./UserContext";
import {FormContextWrapper} from "../core/form_context";
import {LightContainer, TextfieldInput} from "../core/input_fields";

export default function PersonTeamView(props) {
    const [persons, setPersons] = useState([]);
    const context = useContext(UserContext);
    const [{error, loading, success}, setStatusVar] = useState({loading: context.user?.role < 80});
    const loadPersons = () => context.user?.role < 80 && maxiGet("/person", {setStatusVar, fields: ["fullname", "dateOfBirth", "ID"]}).then(data => {
        setPersons(data.persons)
    })


    useMemo(loadPersons, [context.user?.role]);
    return <Container name={"Personen und Mannschaften"} hideHeading>
        <PersonList {...{...props, persons, setPersons, loading, error, loadPersons}}/>
        <TeamList {...{...props, persons}}/>
    </Container>
}
