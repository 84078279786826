import React, {useContext, useMemo, useState} from "react";
import {maxiGet} from "../core/maxios";
import Status from "../core/status";
import {BlankLink, Loader} from "../core/components";
import {UserContext} from "./UserContext";
import {LightContainer} from "../core/input_fields";

function Logo({data}) {
    if (typeof data==="string"){
        return <div dangerouslySetInnerHTML={{__html: data}}/>
    }
    if (data.type === "img") {
        return <img {...data.props}/>
    }
    return React.createElement(data.type, data.props, data.props.children.map(a => a.type ? React.createElement(a.type, a.props, a.props.children) : a))
}

export default function VereinID({}) {
    const [users, setUsers] = useState([]);
    const [{error, loading}, setStatusVar] = useState({});
    const context = useContext(UserContext);

    useMemo(() => setStatusVar({loading: true}) || maxiGet("/vereinid/list", {setStatusVar}).then(({users}) => setUsers(users)), [])
    return <LightContainer name={"Meine anderen Benutzerkonten bei VEREINfacht-Softwarelösungen"}>
        <Status type={"error"} text={error}/>
        <Loader loading={loading}/>
        {
            users.map(({platform, name, login_url, logo}) => <div style={{marginBottom: "30px"}}><Logo data={logo}/><br/><a href={login_url} target={"_blank"} rel={"noopener noreferrer"}>{platform} ({name})</a></div>)
        }
        {
            users.length === 0 && <p>Leider keine anderen Konten gefunden.</p>
        }

        <h3>Was wird hier angezeigt?</h3>
        Hier werden alle Konten bei VEREINfacht-Softwarelösungen angezeigt, die auf die gleiche E-Mail-Adresse ({context?.user?.email}) wie du hier angemeldet bist, lauten.<br/>
        Überprüft werden alle VEREINfacht benutzenden Vereine, die Turnsport Austria Melde- und Lizenzplattform <BlankLink>mein.turnsport.at</BlankLink> und die Melde und Berechnungsplattform von Turnfest / dem ÖTB OÖ <BlankLink>meldung.turnfest.at</BlankLink>.
    </LightContainer>
}
