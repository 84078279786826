import React, {useMemo, useState} from "react";

import {Container, MaxBtn} from "../core/components";
import {MyModal, MyReactTable} from "../core/input_fields";
import {maxiGet, maxiPost} from "../core/maxios";
import Status from "../core/status";
import {AddressPrinter} from "../core/helpers";
import BillingAddressEdit from "./billingAddressEdit";


export default function BillingAddressList({nameCell}) {
    const [billingAddresses, setBillingAddresses] = useState([]);
    const [{error, loading, success}, setStatusVar] = useState({loading: true});
    const loadBillingAddress = () => {
        maxiGet("/billing_address", {setStatusVar}).then(data => {
            setBillingAddresses(data.billing_addresses)
        })
    }
    useMemo(loadBillingAddress, []);
    return <Container name={""}>
        <div style={{float: "right"}}>
            <MyModal trigger={<MaxBtn>Rechnungsadresse hinzufügen</MaxBtn>}>
                {close => <BillingAddressEdit close={close} reload={loadBillingAddress} billingAddress={{
                    country: "AT",
                }}/>}
            </MyModal>
        </div>
        <h2>Rechnungsadressen</h2>
        <Status type={"error"} text={error}/>
        <MyReactTable
            data={billingAddresses}
            loading={loading}
            pageSizeDef={400}
            columns={[
                {
                    Header: "Empfänger",
                    accessor: "recipient",
                    Cell: nameCell || (({original, value}) => value),
                },
                {
                    Header: "Ort",
                    accessor: "city",
                    Cell: ({original}) => <AddressPrinter {...original}/>
                },
                {
                    Header: "",
                    maxWidth: 250,
                    Cell: ({original}) => <>
                        <MyModal trigger={<em>bearbeiten</em>}>
                            {close => <BillingAddressEdit close={close} reload={loadBillingAddress} billingAddress={original}/>}
                        </MyModal>
                        <em onClick={() => {
                            if (window.confirm("Rechnungsadresse überall löschen?")) {
                                maxiPost("/billing_address/hide", {ID: original.ID}).then(loadBillingAddress)
                            }
                        }
                        }>löschen</em>
                    </>
                }
            ]}
        />
    </Container>
}

