import React, {useState} from "react";
import {Container, Loader, MaxBtn} from "../core/components";
import {FormContextWrapper} from "../core/form_context";
import {DateInput, TextfieldInput} from "../core/input_fields";
import {maxiGet} from "../core/maxios";
import {encodeGetParams} from "../core/helpers";
import Status from "../core/status";
import styles from "./Licence.module.sass"
import Consts from "../core/consts"
import {LicencePrinter} from "./licenceQueryHelpers";

export default function LicencePublicQuery({}) {
    const [state, setState] = useState({query: {licenceNumber: Consts.f(50264584, ""), dateOfBirth: Consts.f("1987-01-14", null)}})
    const [{error, loading}, setStatusVar] = useState({});
    const [{licences, person}, setLicence] = useState({});

    const queryLicence = e => {
        e.preventDefault();
        maxiGet("/licence/query?" + encodeGetParams(state.query), {setStatusVar}).then(setLicence)
    }
    return <>
        <Container name={"Turnsport-Austria-Lizenz abfragen"}>
            <div className={styles.licenceQuery}>


                <FormContextWrapper value={{state, setState}} onSubmit={queryLicence}>
                    <Status type={"error"} text={error}/>
                    <TextfieldInput name={"Turnsport-Austria-Lizenz-Nummer"} tag={"query_licenceNumber"} ph={"1234 5678"}/>
                    <br/>
                    <DateInput name={"Geburtsdatum"} tag={"query_dateOfBirth"}/>
                    <br/>
                    <MaxBtn>Abfragen</MaxBtn><Loader loading={loading}/>
                </FormContextWrapper>
            </div>
            <LicencePrinter {...{person, licences}} />
        </Container>


    </>
}
