import React, {useContext, useState} from "react";
import {Container, MaxBtn} from "../core/components";
import {maxiGet} from "../core/maxios";
import {encodeGetParams} from "../core/helpers";
import Status from "../core/status";
import styles from "./Licence.module.sass"
import PersonList from "../user/personList";
import {LicencePrinter} from "./licenceQueryHelpers";
import {MyModal} from "../core/input_fields";
import LicencePermissions from "./licencePermissions";
import {UserContext} from "../user/UserContext";

export default function LicenceQuery({}) {
    const [{error, loading}, setStatusVar] = useState({});
    const [{licences, person}, setLicence] = useState({});
    const context = useContext(UserContext);

    const [persons, setPersons] = useState([]);
    return <>
        <Container name={"Lizenz abfragen"}>
            <Status type={"error"} text={error}/>
            <div className={styles.licenceQuery}>
                <PersonList
                    searchOnly
                    {...{persons, setPersons, loadPersons: () => null,}}
                    nameCell={({original, value}) =>
                        <em onClick={() => {
                            maxiGet("/licence/query?" + encodeGetParams({person_ID: original.ID}), {setStatusVar}).then(setLicence)
                        }
                        }>{value} (abfragen)</em>}
                />
            </div>
            <LicencePrinter {...{person, licences, user: context?.user}} />

            {
                context?.user?.role > 20 && <div>
                    <MyModal trigger={<MaxBtn>Berechtigte Personen</MaxBtn>}>
                        <LicencePermissions/>
                    </MyModal>

                </div>
            }
        </Container>


    </>
}
