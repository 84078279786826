import React from 'react';
import Consts from "../core/consts"
import axios from "axios"
import {UserContext} from "./UserContext";
import {clubMap, domainMap} from "../core/custom";


class CheckLogin extends React.Component {
    static contextType = UserContext;

    constructor(props) {
        super(props);
        const pn = this.props.location.pathname || "";
        if (
            pn.substr(0, 15) !== "/benutzer/login" &&
            pn.substr(0, 19) !== "/benutzer/changepw/" &&
            pn.substr(0, 13) !== "/registrieren" &&

            pn !== "/getCustomClub"
        ) {
            this.getStatus();
            if (!!localStorage.getItem("status_cache")) {
                props.addUserFromStatus({user: JSON.parse(localStorage.getItem("status_cache"))})
            }
        }
    }

    getStatus = () => {
        const pn = this.props.location.pathname || "";
        const publicPage = pn.startsWith("/veranstaltungen") || pn.substr(0, 10) === "/personen" || ["/", "/lizenz/abfragen", "/aus-fortbildungen"].includes(pn);

        axios.get(Consts.API_PREFIX + "/user/status", {
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(response => {
                localStorage.setItem("status_cache", JSON.stringify(response.data));
                if (response.data.status === "error" && !publicPage) {
                    localStorage.setItem("redir", pn);
                    window.location.href = "/benutzer/login"
                } else {

                    if (response.data.status !== "logged_in" && !publicPage) {
                        localStorage.setItem("redir", pn);
                        window.location.href = "/benutzer/login"
                    } else {
                        //console.log("logged in as ", response.data.email)
                        /*if ((response.data.verein_ID !== 1 || Club.verein_ID !== 1) && response.data.verein_ID !== Club.verein_ID && Consts.f(false,true)) {
                            if (window.confirm("Obige Domain passt nicht zu dem Verein in dem du bist. Weiterleiten?")) {
                                window.location.href = "https://" + Club.domain
                            }
                        }*/
                        localStorage.setItem("verein_ID_cache", 1);
                    }
                    const club = domainMap[clubMap["1"]];
                    this.props.addUserFromStatus({user: response.data, club: club})
                }
            })
            .catch((error) => {
                console.log("error", error);
            });
    };

    render() {
        return null
    }

}

export default CheckLogin;
