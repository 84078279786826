import React, {useContext, useMemo, useState} from "react";

import {Loader, MaxBtn} from "../core/components";
import {LightContainer, MyModal, MyReactTable, TextfieldInput} from "../core/input_fields";
import {FormContextWrapper} from "../core/form_context";
import {maxiGet, maxiPost} from "../core/maxios";
import Status from "../core/status";
import UserHistory from "../user/userHistory";
import "./style.sass"
import PersonEdit from "./personEdit";
import PersonList from "./personList";
import {dateFormatTime} from "../core/dateFuncs";
import {UserContext} from "./UserContext";

const fields = {
    name: "Name",
};


export default function TeamEdit({team, reload, close}) {
    const [{error, loading, success}, setStatusVar] = useState({});
    const [state, setState] = useState({team})
    const localTeam = state.team;
    const user = (useContext(UserContext) || {}).user || {}

    const handleSubmit = () => !loading && maxiPost("/team", state.team, {setStatusVar})
        .then((resp) => {
            setState(old => ({team: {...old.team, ID: resp.team_ID}}))
            reload()
        })


    return <LightContainer name={"Mannschaft bearbeiten"}>
        <Status type={"error"} text={error}/>

        <FormContextWrapper value={{state, setState}} onSubmit={handleSubmit}>
            <div>
                {Object.entries(fields).map(([f, fieldName], i) => {
                    switch (f) {
                        default:
                            return <>
                                <TextfieldInput class={"form-control "} style={{width: "480px"}} name={fieldName} tag={"team_" + f}/>
                                <br/>
                            </>;
                    }
                })}
                <br/>
                <MaxBtn>Speichern</MaxBtn><Loader loading={loading}/>
                <br/>
            </div>
        </FormContextWrapper>

        {
            localTeam.ID && <>
                <TeamPersonsList team={localTeam}/>
                {
                    user.role > 80 && <LightContainer name={"Veränderungen"}>
                        <UserHistory userID={localTeam.ID} personType={"team"}/>
                    </LightContainer>
                }
            </>
        }
    </LightContainer>


}

function TeamPersonsList({team}) {
    const [teamPersons, setTeamPersons] = useState([]);
    const [{error, loading, success}, setStatusVar] = useState({loading: true});
    const loadTeamPersons = () => maxiGet(`/team/${team.ID}/persons`, {setStatusVar}).then(data => {
        setTeamPersons(data.teamPersons)
    })
    useMemo(loadTeamPersons, []);
    const [persons, setPersons] = useState([])
    const context = useContext(UserContext);
    const loadPersons = () => context.user?.role < 80 && maxiGet("/person").then(({persons}) => setPersons(persons));
    useMemo(loadPersons, [context.user?.role])

    const teamPersonIds = teamPersons.map(({ID}) => ID);
    return <LightContainer name={"Mannschafts-Mitglieder"}>
        <div style={{float: "right", marginTop: "-60px"}}><MyModal onClose={loadTeamPersons} trigger={<MaxBtn>Person hinzufügen</MaxBtn>}>
            {close => <TeamPersonsAdd close={close} setPersons={setPersons} persons={persons} personsAvailable={persons.filter(p => teamPersonIds.indexOf(p.ID) === -1).map(p => p.ID)} team={team} reload={() => {
                loadTeamPersons()
                loadPersons()
            }}/>}
        </MyModal></div>
        <Status type={"error"} text={error}/>
        <MyReactTable
            data={teamPersons}
            loading={loading}
            columns={[
                {
                    Header: "Name",
                    accessor: "fullname",
                    filterable: true,
                    Cell: ({original, value}) => <PersonEditWrapper reload={loadTeamPersons} personLight={original}>
                        <em>{value}</em>
                    </PersonEditWrapper>
                },
                {
                    Header: "Mitglied seit",
                    accessor: "memberSince",
                    Cell: ({value}) => dateFormatTime(new Date(value))
                },
                {
                    maxWidth: 250,
                    Cell: ({original, value}) => <>
                        <em onClick={() => {
                            if (window.confirm("Person aus Team entfernen?")) {
                                maxiPost(`/team/${team.ID}/delete_person`, {person_ID: original.ID}).then(loadTeamPersons)
                            }
                        }
                        }>löschen</em>
                    </>
                }
            ]}
        />
    </LightContainer>
}

function TeamPersonsAdd({persons, personsAvailable, setPersons, team, close, reload}) {
    const [{error, loading, success}, setStatusVar] = useState({});

    const handleSubmit = (person) => {
        !loading && maxiPost(`/team/${team.ID}/add_person`, {person_ID: person.ID}, {setStatusVar})
            .then(() => {
                reload()
            })
    }


    return <LightContainer name={"Person hinzufügen"}>
        <Status type={"error"} text={error}/>
        <PersonList
            persons={persons}
            setPersons={setPersons}
            nameCell={({original, value}) => personsAvailable.includes(original.ID) ? <em onClick={() => {
                handleSubmit(original)
            }}>{value} (hinzufügen)</em> : <>{value} (bereits ausgewählt)</>}
            error={""}
            loadPersons={reload}
            match={{}}
            loading={false}
        />
    </LightContainer>
}


function PersonEditWrapper({personLight, reload, children}) {
    const [person, setPerson] = useState(null);
    const loadPerson = () => maxiGet("/person/" + personLight.ID).then(resp => {
        setPerson(resp.person)
    })

    return <MyModal trigger={children} onOpen={loadPerson}>
        {close => person && <PersonEdit {...{person, reload, close}}/>}
    </MyModal>
}
