import React from "react"
import Consts from "./consts";

type _Name = {
    verein_ID: number,
    fullName: string,
    fullNameDeclined: string,
    shortName: string,
    domain: string,
    title: string,
    logo?: JSX.Element,
    color: string,
    colorLight: string,
    fontColor?: string,
    fontColorInverse?: string,
    showLogo: boolean,
    iban: string,
    facebook?: string,
    memberStates: Record<number, string>,
    memberStatesShort?: Record<number, string>,
    arnName: string,
    arn: string,
    sendReminders: boolean,
    address: string,
    creditorID: string,
    email?: string,
    phone?: number,
    links?: {
        dataprotection?: string
        statuten?: string
    }
}

type _domainMap = Record<string, _Name>

const domainMapInitial: _domainMap = {
    "mein.turnsport.at": {
        verein_ID: 1,
        fullName: "Turnsport Austria Melde- und Lizenzportal",
        fullNameDeclined: "Turnsport Austria  Melde- und Lizenzportal",
        shortName: "Turnsport Austria",
        domain: "mein.turnsport.at",
        title: "Turnsport Austria Melde- und Lizenzportal",
        logo: <img alt={"oeft logo"} width={300} height={"auto"} src={"/static/img/logo.svg"}/>,
        showLogo: true,
        color: "#ff0000",
        colorLight: "rgba(0,0,0,0.06)", //optional rgba(255,118,0,0.1)
        fontColor: "#fff",
        fontColorInverse: "#fff",
        iban: "",
        facebook: "Oeft",
        memberStates: {},
        arnName: "<office@turnsport.at>",
        arn: "arn:aws:ses:eu-west-1:639823883075:identity/oeft.at",
        sendReminders: true,
        address: "Schwarzenbergplatz 10, 1040 Wien",
        creditorID: "",
    }
};
const domainMap: _domainMap = Object.keys(domainMapInitial).reduce((obj, curr) => ({
    ...obj,
    [curr]: {
        ...domainMapInitial[curr],
        iban: domainMapInitial[curr].iban,
        memberStatesShort: Object.keys(domainMapInitial[curr].memberStates)
            .reduce((obj, k) => ({
                ...obj,
                [parseInt(k)]: domainMapInitial[curr].memberStates[parseInt(k)].split(" ").map((u: any) => u[0]).join(".")
            }), {})
    }
}), {});
const domainName = window.location.hostname.split(".").slice(-2).join(".");
const domainNameLong = window.location.hostname.split(".").slice(-3).join(".");
const clubMap = Object.keys(domainMap).reduce((obj, curr) => ({...obj, [domainMap[curr].verein_ID]: curr}), {});


const ClubPart = domainMap["mein.turnsport.at"];

const Club = {
    ...ClubPart,
}; // domain: window.location.host


export {Club, domainMap, clubMap};
