import React, {useMemo, useState} from "react";
import {LightContainer, MyModal, MyReactTable} from "../core/input_fields";
import {maxiGet, maxiPost} from "../core/maxios";
import {dateFormat} from "../core/dateFuncs";
import Consts from "../core/consts";
import Status from "../core/status";
import {Loader, MaxBtn} from "../core/components";
import PersonList from "../user/personList";
import {OfferTypeMap, PersonTypeMap, RegistrationStatusMap} from "../core/enums";


export default function OffersShow({registeredPersons: registeredPersonsRaw, registration, offers, event}) {
    const registeredPersons = registeredPersonsRaw.reduce((obj, curr) => ({...obj, [curr.registeredPerson.ID]: curr}), {})
    const [registeredPersonOffers, setRegisteredPersonOffers] = useState({});
    const [sum, setSum] = useState(null);
    const [{error, loading, success}, setStatusVar] = useState({loading: true});
    const loadRegistration = () => {
        maxiGet(`/registration/${registration.ID}/registered_person_offer`, {setStatusVar}).then(({registered_person_offer, sum}) => {
            setRegisteredPersonOffers(registered_person_offer)
            setSum(sum)
        })
    }
    const ids = registeredPersonsRaw.map(a => a.registeredPerson.ID).join(",");
    useMemo(loadRegistration, [ids])
    return <>
        <LightContainer name={"Zuordnung"}>
            <Status type={"error"} text={error}/>
            <Loader loading={loading}/>
            <OfferPrint {...{offers, registeredPersons, registeredPersonOffers, event, loadRegistration, registration}}/>

        </LightContainer>
        {
            sum !== null &&
            <LightContainer name={"Gesamtsumme"}>
                <b>Die Kosten für diese Meldung belaufen sich auf {Consts.money.format(sum)}.</b>
            </LightContainer>
        }
    </>
}
const now = new Date();

function OfferPrint({offers, parentOffer, registeredPersons, registeredPersonOffers, event, loadRegistration, registration}) {
    //const bookersOfAllOptions = offers.filter(({type}) => type.value === OfferTypeMap.selection).reduce((arr, offer) => [...arr, ...(registeredPersonOffers[offer.ID.toString()]?.map(({registeredPerson_ID}) => registeredPerson_ID) || [])], []);
    const bookersOfAllOptionsIDs = offers
        .filter(({type}) => type.value === OfferTypeMap.selection)
        .reduce((arr, offer) => [...arr, ...(registeredPersonOffers[offer.ID.toString()]?.map(({registeredPerson_ID}) => registeredPerson_ID) || [])], []);

    return offers.map((offer, i) => {
        const bookers = registeredPersonOffers[offer.ID.toString()] || [];
        const bookersIDs = offer.type.value === OfferTypeMap.optional ?
            registeredPersonOffers[offer.ID.toString()]?.map(({registeredPerson_ID}) => registeredPerson_ID) || [] :
            bookersOfAllOptionsIDs;
        const registeredPersonsAvailable = Object.values(registeredPersons)
            .filter(({registeredPerson}) => bookersIDs.indexOf(registeredPerson.ID) === -1 &&
                (offer.onlyOfficials === 0 || ((registeredPerson.role.value === PersonTypeMap.official && offer.onlyOfficials === 1) || (registeredPerson.role.value === PersonTypeMap.participant && offer.onlyOfficials === 2)))
            )
            .map(({registeredPerson}) => registeredPerson.person_ID)
        return <>
            {
                i === 0 && offer.type.value === OfferTypeMap.selection && registeredPersonsAvailable.length > 0 && <div style={{color: "red"}}>
                    Es {registeredPersonsAvailable.length === 1 ? "muss" : "müssen"} noch {registeredPersonsAvailable.length === 1 ? "eine" : registeredPersonsAvailable.length} Person{registeredPersonsAvailable.length > 1 && "en"} in eine dieser Optionen eingebucht werden.
                    <br/>
                    <br/>
                </div>
            }
            {
                (offer.type.value !== OfferTypeMap.selection || bookers.length > 0 || registration.status.value === RegistrationStatusMap.draft) && <div style={{
                    padding: "20px", marginBottom: "20px",
                    ...(offer.type.value === OfferTypeMap.selection ?
                            {border: "2px solid var(--mainColor)", borderRadius: "8px", paddingLeft: "20px", backgroundColor: "white"} :
                            {
                                borderRadius: "8px",
                                backgroundColor: "var(--maincolorlight)",
                                "borderBottomf": "4px solid var(--mainColor)"
                            }
                    )
                }}>

                    {
                        (offer.suboffers?.length || 0) === 0 && offer.type.value !== OfferTypeMap.mandatory && registration.status.value === RegistrationStatusMap.draft && registeredPersonsAvailable.length > 0 && event.bookingStart < now && now < event.bookingEnd &&
                        <>
                            {/*gebucht für:*/}
                            <div style={{float: "right"}}>
                                <MyModal trigger={<MaxBtn>Person hinzufügen</MaxBtn>}>
                                    {close => <RegisteredPersonsAdd {...{
                                        persons: Object.values(registeredPersons).filter(({registeredPerson}) =>
                                            (offer.onlyOfficials === 0 || ((registeredPerson.role.value === PersonTypeMap.official && offer.onlyOfficials === 1) || (registeredPerson.role.value === PersonTypeMap.participant && offer.onlyOfficials === 2)))
                                        ),
                                        personsAvailable: registeredPersonsAvailable,
                                        offer, registration,
                                        reload: loadRegistration
                                    }}/>}
                                </MyModal>
                            </div>
                        </>
                    }
                    <h3>
                        {offer.description} ({offer.type.label})&nbsp;
                        {offer.num_bookings / offer.maxParts > 0.8 && `(${Math.max(0, offer.maxParts - offer.num_bookings)} Plätze verfügbar)`}
                    </h3>

                    {
                        (offer.suboffers?.length || 0) === 0 ? <>
                            {offer.price > 0 && Consts.moneyMax(offer.price)}
                            {
                                now < event.prebookingEnd && offer.discountedPrice != null &&
                                <>, bis {dateFormat(event.prebookingEnd)} nur {Consts.moneyMax(offer.discountedPrice)}</>
                            }
                            <br/>
                            <br/>
                            {
                                offer.type.value !== OfferTypeMap.mandatory ? <>
                                    {/*gebucht für:*/}

                                    <MyReactTable
                                        data={bookers.map((booking) => ({
                                                ...booking,
                                                ...registeredPersons[booking.registeredPerson_ID]
                                            })
                                        )}
                                        columns={[
                                            {
                                                Header: "Name",
                                                accessor: "person.fullname",
                                            },
                                            {
                                                Header: "Kosten",
                                                accessor: "price",
                                                show: bookers.some(a => a.price > 0),
                                                Cell: ({value}) => Consts.moneyMax(value ?? (now < event.prebookingEnd && offer.discountedPrice != null ? offer.discountedPrice : offer.price))
                                            },
                                            {
                                                Cell: ({original}) => <em onClick={() => {
                                                    maxiPost(`/registration/${registration.ID}/delete_person_from_offer`, {registeredPerson_ID: original.registeredPerson_ID, offer_ID: offer.ID},).then(() => {
                                                        loadRegistration()
                                                    })
                                                }
                                                }>löschen</em>,
                                                maxWidth: 250,
                                                show: registration.status.value === RegistrationStatusMap.draft,
                                            }
                                        ]}
                                    />
                                </> : <>
                                    Pflicht für alle
                                </>
                            }
                        </> : <>

                        </>
                    }
                    {offer.suboffers && <OfferPrint {...{offers: offer.suboffers, registeredPersons, event, registration, registeredPersonOffers, loadRegistration}}/>}
                </div>
            }
        </>
    })
}


function RegisteredPersonsAdd({persons, personsAvailable, offer, registration, close, reload}) {

    const [{error, loading, success}, setStatusVar] = useState({});

    const handleSubmit = (person) => {
        !loading && maxiPost(`/registration/${registration.ID}/add_person_to_offer`, {offer_ID: offer.ID, registeredPerson_ID: person.registeredPerson_ID}, {setStatusVar})
            .then(() => {
                reload && reload()
            })
    }
    return <LightContainer name={"Person hinzufügen"}>
        <Status type={"error"} text={error}/>
        <PersonList
            persons={Object.values(persons).map(({person, registeredPerson}) => ({...person, registeredPerson_ID: registeredPerson.ID}))}
            nameCell={({original, value}) => personsAvailable.includes(original.ID) ? <em onClick={() => {
                handleSubmit(original)
            }}>{value} (hinzufügen)</em> : <>{value} (bereits ausgewählt)</>}
            error={""}
            loadPersons={() => null}
            match={{}}
            loading={false}
        />
    </LightContainer>
}
