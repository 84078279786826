import React, {useMemo, useState} from "react";
import {Container, Loader, MaxBtn, MaxLink} from "../core/components";
import {EnumInput, LightContainer, MyReactTable} from "../core/input_fields";
import {FormContextWrapper} from "../core/form_context";
import {LicenceStatus, LicenceStatusLabelMap, LicenceStatusMap} from "../core/enums";
import {maxiGet, maxiPost} from "../core/maxios";
import Status from "../core/status";
import {encodeGetParams} from "../core/helpers";
import {dateFormat, dateFormatTime} from "../core/dateFuncs";
import PersonList from "../user/personList";
import {PersonEditWrapper} from "../booking/registrationPersonTeamMod";
import {LicenceForm} from "./licenceForm";
import {Link, Redirect} from "react-router-dom";
import {downloadAsExcel} from "../core/download";

export function LicenceListPage({match}) {

    const [state, setState] = useState({
        filter: {
            status: LicenceStatus.find(a => a.value === match?.params?.status) || LicenceStatus.find(a => a.value === LicenceStatusMap.submitted)
        }
    })
    const [{loading, error}, setStatusVar] = useState({});
    const [licences, setLicences] = useState([])
    const loadLicences = (status = state.filter?.status?.value) => maxiGet("/licence/list?" + encodeGetParams({status}), {setStatusVar}).then(setLicences)
    useMemo(loadLicences, [])

    if (state.filter.status.value !== match?.params?.status) {
        return <Redirect to={"/lizenz/anträge/" + state.filter.status.value}/>
    }

    return <Container name={"Lizenzanträge"}>
        <Status type={"error"} text={error}/>
        <FormContextWrapper value={{state, setState}} onSubmit={e => e.preventDefault()} afterUpdate={(a, b, c) => {
            loadLicences(c.status.value)
        }}>
            <EnumInput name={"Status"} tag={"filter_status"} type="reactselect" selectives={LicenceStatus}/>
            &nbsp;&nbsp;&nbsp;
            <MaxLink to={"/lizenz/personen"}>nach Person filtern</MaxLink>&nbsp;&nbsp;
            <MaxBtn onClick={() => maxiGet("/licence/list/download?"+encodeGetParams({status: state.filter?.status?.value}), {setStatusVar}).then(a => downloadAsExcel(a.content, "Lizenzen.xlsx"))
            }>
                Excel Download
            </MaxBtn>
        </FormContextWrapper>
        <LicenceList hideStatus licences={licences}/>

    </Container>
}


export function LicenceListPersonen(props) {


    const [persons, setPersons] = useState([]);
    const [person_ID, setPerson] = useState(null)

    return <Container name={"Lizenzanträge"}>

        <p>
            <MaxLink to={"/lizenz/anträge"}>nach Status anzeigen </MaxLink>
        </p>
        <PersonList
            {...{...props, persons, setPersons, loadPersons: () => null,}}
            nameCell={({original, value}) => <em onClick={() => {
                setPerson(original.ID)
            }}>{value} (abfragen)</em>}
        />

        <LicenceListPerson person_ID={person_ID}/>

    </Container>
}

export function LicenceListPerson({person_ID}) {
    const [{loading, error}, setStatusVar] = useState({});
    const [licences, setLicences] = useState([])

    const loadLicences = (p = person_ID) => p && maxiGet("/licence/list/person?" + encodeGetParams({person_ID: p}), {setStatusVar}).then(setLicences)
    useMemo(loadLicences, [person_ID])
    return <LightContainer name={"Lizenzen"}>
        <Status type={"error"} text={error}/>
        <Loader loading={loading}/>
        <LicenceList licences={licences}/>
    </LightContainer>
}

export function LicenceList({licences, hideStatus}) {
    return <>
        <MyReactTable
            columns={[
                {
                    Header: "Turnsport-Austria-Lizenz",
                    filterable: true,
                    accessor: "licence.kind.label",
                    Cell: ({value, original}) => <Link to={"/lizenz/" + original.licence.ID}>{value}</Link>
                    /*Cell: ({value, original}) => <MyModal trigger={<em>{value}</em>}>
                        <LicenceGetWrapper licence={original.licence}/>
                    </MyModal>*/
                },
                {
                    Header: "Sparte",
                    filterable: true,
                    accessor: "licence.sector.label",
                    /*Cell: ({value, original}) => <MyModal trigger={<em>{value}</em>}>
                        <LicenceGetWrapper licence={original.licence}/>
                    </MyModal>*/
                },
                {
                    Header: "Antragsteller",
                    filterable: true,
                    accessor: "person.fullname",
                    Cell: ({value, original}) => <>
                        <PersonEditWrapper personLight={original.person}><em>{value}</em></PersonEditWrapper>
                    </>
                },
                {
                    Header: "Vereine",
                    filterable: true,
                    id: "clubs",
                    accessor: raw => raw.clubs.length ? raw.clubs.sort((a, b) => a.value < b.value ? 1 : -1).map(a => a.label).join(", ") : raw.person.clubOld,
                },
                {
                    Header: "BL",
                    filterable: true,
                    accessor: "person.state",
                    maxWidth: 60,
                },
                {
                    Header: "Status",
                    show: !hideStatus,
                    accessor: "licence.status.label",
                    fitlerable: true,
                },
                {
                    Header: "Abgeschickt",
                    filterable: true,
                    //accessor: row=>dateFormat(row.licence.timeSubmitted),
                    accessor: "licence.timeSubmitted",
                    Cell: ({value}) => dateFormat(value)
                }
            ]}
            data={licences}
        />

    </>
}


export function LicenceGetWrapper({licence}) {
    const [qualifications, setQualifications] = useState(null);
    const [{loading, error}, setStatusVar] = useState({});

    useMemo(() => maxiGet("/licence/" + licence.ID + "/qualifications", {setStatusVar}).then(({qualifications}) => setQualifications(qualifications)), [licence.ID]);

    return <Container name={"Turnsport-Austria-Lizenz"}>
        <Status type={"error"} text={error}/>
        {qualifications && <LicenceForm licenceArg={licence} qualifications={qualifications} setQualifications={setQualifications}/>}

    </Container>
}

export function LicencePageWrapper({match}) {
    const licence_ID = parseInt(match.params?.licence_ID)
    const [qualifications, setQualifications] = useState(null);
    const [licence, setLicence] = useState(null);
    const [person, setPerson] = useState(null);
    const [valid, setValid] = useState(null);
    const [{loading, error}, setStatusVar] = useState({});


    const loadLicence = () => maxiGet("/licence/" + licence_ID + "/qualifications", {setStatusVar})
        .then(({qualifications, person, licence, validity}) => {
            setLicence({...licence, validTillCalculated: !licence.validTill, validTill: licence.validTill || validity.till})//, validTill: new Date(licence.validTill)
            setPerson(person)
            setValid(validity)
            setQualifications(qualifications)
        })

    useMemo(loadLicence, [licence_ID]);

    const licenceAction = (action) => () => window.confirm("Wirklich?") && maxiPost("/licence/" + licence_ID + "/" + action, {}, {setStatusVar}).then(loadLicence)

    return <Container name={"Turnsport-Austria-Lizenz"}>
        <Status type={"error"} text={error}/>
        <Loader loading={loading}/>
        {
            person && <>
                <h3>Person: {person.fullname}</h3>
                <p>
                    {dateFormat(person.dateOfBirth)}<br/>
                    {person.email}
                </p>
                <p>
                    Antrag abgeschickt: {licence.timeSubmitted ? <>am {dateFormatTime(licence.timeSubmitted)}</> : "noch nicht"}<br/>
                </p>
                <p>
                    <b>Status: {LicenceStatusLabelMap[licence.status.value]}</b>
                </p>

                {
                    licence.status.value === LicenceStatusMap.submitted && <>
                        <p>
                            Lizenz würde gültig sein <b>bis {dateFormat(valid?.till)} {!licence.validTillCalculated && <>überschrieben zu {dateFormat(licence.validTill)}</>}</b>
                        </p>
                        <p>
                            <MaxBtn onClick={licenceAction("approve")}>
                                Gewähren
                            </MaxBtn>&nbsp;&nbsp;
                            <MaxBtn onClick={licenceAction("disapprove")}>
                                Ablehnen
                            </MaxBtn>
                            &nbsp;&nbsp;
                            <MaxBtn onClick={licenceAction("reopen")}>
                                Bearbeitung wieder öffnen
                            </MaxBtn>
                        </p>
                    </>
                }
                {
                    licence.status.value === LicenceStatusMap.approved && <p>
                        Gültig <b>von {dateFormatTime(licence.timeApproved)} bis {dateFormat(licence.validTill)}</b>
                    </p>
                }
                <br/>
                <br/>

            </>
        }
        {qualifications && licence && <LicenceForm licenceArg={licence} qualifications={qualifications}  reload={loadLicence} setQualifications={setQualifications}/>}

    </Container>
}

