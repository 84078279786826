import React, {useContext, useMemo, useState} from "react";

import {Container, Loader, MaxBtn} from "../core/components";
import {LightContainer, MyModal, MyReactTable, TextfieldInput} from "../core/input_fields";
import {FormContextWrapper} from "../core/form_context";
import {maxiGet, maxiPost} from "../core/maxios";
import Status from "../core/status";
import {UserContext} from "../user/UserContext";
import "./style.sass"
import EventEdit from "./eventEdit";
import {dateFormat, dateFormatDateInline} from "../core/dateFuncs";
import OfferList from "./offerList";
import {Redirect} from "react-router";
import {EventRegistrationList} from "./eventRegistrations";
import PersonList from "../user/personList";


export default function EventView({match}) {
    const event_ID = match?.params?.event_ID;
    const addNew = event_ID === "neu";
    const [event, setEvent] = useState(addNew ? {} : null);
    const [offers, setOffers] = useState([]);
    const [{error, loading, success}, setStatusVar] = useState({loading: !addNew});
    const user = useContext(UserContext)?.user || {}
    const loadEvent = () => {
        maxiGet(`/event/${event_ID}`, {setStatusVar}).then(({event, offers}) => {
            setOffers(offers)
            setEvent({
                ...event,
                eventStart: new Date(event.eventStart),
                eventEnd: new Date(event.eventEnd),
                bookingStart: new Date(event.bookingStart),
                bookingEnd: new Date(event.bookingEnd),
            })
        })
    }
    useMemo(() => {
        !addNew && loadEvent()
    }, [])
    return <Container name={"Veranstaltung "} addName={event?.name}>

        <Status type={"error"} text={error}/>
        <Loader loading={loading}/>
        {
            event && !addNew && <EventPrint event={event} user={user}/>
        }


        {
            user.role === 100 && event && <>
                {event && !addNew && <EventRegistrationList event={event}/>}
                {!addNew && <OfferList {...{reload: loadEvent, event_ID, event, offers}}/>}
                <EventEdit {...{reload: loadEvent, event}}/>
            </>
        }

    </Container>
}
const now = new Date();

function EventPrint({event, user}) {
    const [registrationID, setRegistrationID] = useState(null);
    const {city, eventStart, eventEnd, description} = event
    const addNewRegistration = () => maxiPost("/registration/add", {event_ID: event.ID}).then(resp => {
        setRegistrationID(resp.registration_ID);
    }).catch(e => {
        if (e === "nicht eingeloggt") {

            localStorage.setItem("redir", "/veranstaltungen/" + event.ID);
            window.location.href = "/benutzer/login"
        }
    })

    if (registrationID) {
        return <Redirect to={"/anmeldungen/" + registrationID}/>
    }
    return <div>
        <b>{city}, {eventStart && dateFormat(eventStart)} bis {eventEnd && dateFormat(eventEnd)}{now < event.bookingEnd && <>, Anmeldung bis {dateFormat(event.bookingEnd)}</>}</b>

            <div dangerouslySetInnerHTML={{__html: description}}/>
        {event.bookingStart < now && now < event.bookingEnd && (!user?.permission || user.permission>=event.minPermission) && <MaxBtn onClick={addNewRegistration}>Anmelden</MaxBtn>}

        &nbsp;
        &nbsp;
        &nbsp;
        <UserContext>
            {context => context.user?.role > 80 && <AddRegistrationForPerson event={event} setRegistrationID={setRegistrationID}/>}
        </UserContext>
    </div>
}


function AddRegistrationForPerson({event, setRegistrationID}) {
    const [{error, loading, success}, setStatusVar] = useState({});
    const [persons, setPersons] = useState([]);

    const context = useContext(UserContext);
    useMemo(() => context.user?.role < 80 && maxiGet("/person").then(({persons}) => setPersons(persons)), [context.user?.role])

    const handleSubmit = (person) => {
        !loading && maxiPost(`/registration/add_superuser`, {event_ID: event.ID, person_ID: person.ID}, {setStatusVar})
            .then((resp) => {
                setRegistrationID(resp.registration_ID)
            })
    }
    return <MyModal trigger={<MaxBtn>Registrierung für Person hinzufügen</MaxBtn>}>
        <LightContainer name={"Person hinzufügen"}>
            <Status type={"error"} text={error}/>
            <PersonList
                persons={persons}
                error={""}
                setPersons={setPersons}
                nameCell={({original, value}) => <em onClick={() => {
                    handleSubmit(original)
                }}>{value} (hinzufügen)</em>}
                loadPersons={() => null}
                match={{}}
                loading={false}
            />
        </LightContainer>
    </MyModal>
}
