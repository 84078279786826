import React, {useMemo, useState} from "react";
import {Container, MaxBtn} from "../core/components";
import {maxiDelete, maxiGet, maxiPost} from "../core/maxios";
import Status from "../core/status";
import {MyModal, MyReactTable} from "../core/input_fields";
import {PersonEditWrapper} from "../booking/registrationPersonTeamMod";
import PersonList from "../user/personList";
import {dateFormat} from "../core/dateFuncs";

export default function LicencePermissions({}) {
    const [{error, loading}, setStatusVar] = useState({});

    const [personsAllowed, setPersonsAllowed] = useState([]);
    const [person_IDs, setPerson_IDs] = useState([]);


    const loadPersons = () => maxiGet("/licence/persons_permitted", {setStatusVar}).then((ps) => {
        setPersonsAllowed(ps)
        setPerson_IDs(ps.map(a => a.ID))
    })
    useMemo(loadPersons, [])
    const [persons, setPersons] = useState([]);

    return <Container name={"Berechtigungen Lizenzen abzufragen"}>
        <Status type={"error"} text={error}/>
        <MyModal trigger={<MaxBtn>Person hinzufügen</MaxBtn>}>
            <PersonList
                {...{persons, setPersons, loadPersons: () => null,}}
                nameCell={({original, value}) => person_IDs.includes(original.ID) ?
                    value + " (bereits)" :
                    <em onClick={() => {
                        maxiPost("/licence/person_permitted", {person_ID: original.ID}).then(loadPersons)
                    }
                    }>{value} (hinzufügen)</em>}
            />
        </MyModal><br/>
        <MyReactTable
            data={personsAllowed}
            columns={[
                {
                    Header: "Name",
                    accessor: "fullname",
                    filterable: true,
                    Cell: ({value, original}) => <>
                        <PersonEditWrapper personLight={original}><em>{value}</em></PersonEditWrapper>
                    </>
                },
                {
                    Header: "berechtigt seit",
                    accessor: "licenceQueryAllowed",
                    Cell: ({value, original}) => <>
                        {dateFormat(value)}
                        <em onClick={() => window.confirm("wirklich?") && maxiDelete("/licence/person_permitted/" + original.ID, {setStatusVar}).then(loadPersons)}>entziehen</em>
                    </>
                },
            ]}
        />
    </Container>
}
