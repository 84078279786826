import React, {useContext, useMemo, useState} from "react";

import {Container, Loader, MaxLink} from "../core/components";
import {MyReactTable} from "../core/input_fields";
import {maxiGet, maxiPost} from "../core/maxios";
import Status from "../core/status";
import {Link} from "react-router-dom";
import {dateFormatTimeShort} from "../core/dateFuncs";
import {RegistrationStatusMap} from "../core/enums";
import {UserContext} from "../user/UserContext";
import {PersonEditWrapper} from "./registrationPersonTeamMod";


export default function RegistrationList({}) {
    const [registrations, setRegistrations] = useState([]);
    const user = useContext(UserContext);
    const [{error, loading, success}, setStatusVar] = useState({loading: true});
    const loadRegistrations = () => {
        maxiGet("/registration", {setStatusVar}).then(data => {
            setRegistrations(data.registrations)
        })
    }
    useMemo(loadRegistrations, []);
    return <Container name={"Anmeldungen"}>
        <div style={{float: "right", marginTop: "-50px"}}>
            <MaxLink to={"/veranstaltungen"}>Veranstaltungen anzeigen</MaxLink>
        </div>
        <Status type={"error"} text={error}/>
        <Loader block loading={loading}/>
        <MyReactTable
            data={registrations}
            loading={loading}
            pageSizeDef={400}
            defaultSorted={[{id: "registration.timeAdded", desc: true}]}
            columns={[
                {
                    Header: "Veranstaltung",
                    accessor: "event.name",
                    filterable: true,
                },
                {
                    Header: "",
                    maxWidth: 250,
                    Cell: ({original}) => <>
                        <Link to={"/anmeldungen/" + original.registration.ID}>{original.registration.status.value === RegistrationStatusMap.draft ? "bearbeiten" : "anschauen"}</Link>
                        {
                            original.registration.status.value === RegistrationStatusMap.draft && original.registration.timeFinalized === null &&
                            <em onClick={() => {
                                if (window.confirm("Anmeldung abbrechen und verwerfen")) {
                                    maxiPost("/registration/hide", {registration_ID: original.registration.ID}, {setStatusVar}).then(loadRegistrations)
                                }
                            }
                            }>abbrechen</em>
                        }
                    </>
                },
                {
                    Header: "Registrant",
                    accessor: "owner.fullname",
                    filterable: true,
                    Cell: ({original, value}) => <PersonEditWrapper personLight={original.owner} reload={loadRegistrations}><em>{value}</em></PersonEditWrapper>,
                },
                {
                    Header: "Status",
                    accessor: "registration.status.label",
                    filterable: true,
                },
                {
                    Header: "Anzahl an Personen",
                    accessor: "count",
                    filterable: true,
                },
                {
                    Header: "Anmeldung gestartet",
                    accessor: "registration.timeAdded",
                    Cell: ({value}) => value ? dateFormatTimeShort(value) : "",
                    filterable: true,
                },
                {
                    Header: "Anmeldung abgeschickt",
                    accessor: "registration.timeFinalized",
                    Cell: ({value}) => value ? dateFormatTimeShort(value) : "",
                    filterable: true,
                },

            ]}
        />
    </Container>
}

