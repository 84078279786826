import React, {useContext, useMemo, useState} from "react";
import {Container, Loader, MaxBtn, MaxLink} from "../core/components";
import {maxiDelete, maxiGet, maxiPost} from "../core/maxios";
import {encodeGetParams} from "../core/helpers";
import {LicencePrinter} from "./licenceQueryHelpers";
import {UserContext} from "../user/UserContext";
import Status from "../core/status";
import {QualificationsList} from "./licenceQualifications";
import {DateInput, LightContainer, MyModal, MyReactTable, TextfieldInput} from "../core/input_fields";
import {dateFormat} from "../core/dateFuncs";
import {FaEdit, FaPlus} from "react-icons/all";
import {FormContextWrapper} from "../core/form_context";
import UserHistory from "../user/userHistory";

export default function LicenceProfile({}) {
    const [{error, loading}, setStatusVar] = useState({});
    const [{licences, person}, setLicence] = useState({});

    const context = useContext(UserContext);

    const queryLicence = e => context.user?.ID && maxiGet("/licence/query?" + encodeGetParams({person_ID: context.user.ID}), {setStatusVar}).then(setLicence)
    useMemo(queryLicence, [context.user?.ID])
    return <Container name={"Meine Turnsport-Austria-Lizenzen"}>
        <Status type={"error"} text={error}/>
        <MaxLink to={"/lizenz/beantragen"}>Turnsport-Austria-Lizenz beantragen</MaxLink>

        {
            context?.user?.role > 80 && <>&nbsp;<MaxLink to={"/lizenz/anträge"}>Anträge anzeigen</MaxLink></>
        }
        {
            (context?.user?.licenceQueryAllowed !== null || context?.user?.role > 20) && <>&nbsp;
                <MaxLink to={"/lizenz/suche"}>Turnsport-Austria-Lizenz mit Name abfragen</MaxLink>
                &nbsp;<MaxLink to={"/veranstaltungen/absolventen"}>Absolventen</MaxLink>
            </>
        }
        &nbsp;<MaxLink to={"/lizenz/abfragen"}>Turnsport-Austria-Lizenz mit Lizenz-Nummer abfragen</MaxLink>
        <h1>Buchbare Aus-/Fortbildungen</h1>
        &nbsp;<MaxLink to={"/aus-fortbildungen"}>Buchbare Aus-/Fortbildungen anzeigen</MaxLink>

        <LicencePrinter {...{person, licences, user: context.user}} />

        <QualificationsList/>


    </Container>
}

