import React, {useContext, useMemo, useState} from "react";

import {Container, MaxBtn} from "../core/components";
import {LightContainer, MiniBtn, MyModal, MyReactTable} from "../core/input_fields";
import {UserContext} from "../user/UserContext";
import "./style.sass"
import Consts from "../core/consts";
import {OfferType, OfferTypeMap} from "../core/enums";
import OfferEdit from "./offerEdit";
import {FaAngleRight} from "react-icons/all";
import {dateFormat, dateFormatTimeShort} from "../core/dateFuncs";
import {maxiGet} from "../core/maxios";
import Status from "../core/status";


export default function OfferList({event_ID,event, offers, reload}) {
    const user = useContext(UserContext)?.user || {}

    return <LightContainer name={"Optionen"}>
        {
            user.role === 100 &&
            <div style={{float: "right", marginTop: "-60px"}}>
                <OfferConfigExplanation/>
                &nbsp;
                &nbsp;
                <MyModal trigger={<MaxBtn>Option hinzufügen</MaxBtn>}>
                    {close => <OfferEdit offer={{event_ID, price: 0, discountedPrice: 0, onlyOfficials: 0, description: ""}} event={event} reload={reload} close={close}/>}
                </MyModal>&nbsp;

            </div>
        }
        <MyReactTable
            data={offers}
            pageSizeDef={400}
            columns={[

                {
                    Header: "Bezeichnung",
                    accessor: "description",
                    Cell: ({value, original}) => <>
                        {original.parent_ID !== original.ID && <FaAngleRight/>}
                        <MyModal trigger={<em>{value}</em>}>
                            {close => <OfferEdit offer={original} reload={reload} event={event} close={close}/>}
                        </MyModal>
                    </>
                },
                {
                    Header: "Art",
                    accessor: "type",
                    Cell: ({value}) => value.label,
                    maxWidth: 120,
                },
                {
                    Header: "Buchbar bis",
                    accessor: "bookingEnd",
                    Cell: ({value}) => dateFormat(value),
                    maxWidth: 150,
                },
                {
                    Header: "Kosten",
                    accessor: "price",
                    maxWidth: 180,
                    Cell: ({value, original}) => `${Consts.moneyMax(value)} (${Consts.moneyMax(original.discountedPrice)})`
                },
                {
                    Header: "",
                    maxWidth: 380,
                    Cell: ({original}) => <>
                        <MyModal trigger={<MiniBtn>Buchungen ({original.num_bookings})</MiniBtn>}>
                            <ShowBookingsOfOption offer_ID={original.ID}/>
                        </MyModal>
                        &nbsp;
                        {
                            original.type.value === OfferTypeMap.mandatory &&
                            <MyModal trigger={<MiniBtn>Auswahlmöglichkeit hinzufügen</MiniBtn>}>
                                {close => <OfferEdit offer={{
                                    event_ID, event, parent_ID: original.ID, price: 0, type: OfferType.find(o => o.value === OfferTypeMap.selection), discountedPrice: 0, onlyOfficials: 0, description: ""
                                }} reload={reload} close={close}/>}
                            </MyModal>
                        }
                    </>
                }
            ]}
        />
    </LightContainer>
}


function OfferConfigExplanation({}) {
    return <MyModal trigger={<MaxBtn>Erklärungen</MaxBtn>}>
        <Container name={"Erklärungen"}>
            Optionen, wo mehrere Auswahlmöglichkeiten zur Verfügung stehen, müssen Unteroptionen (Typ Auswahl) einer Pflicht-Option sein.<br/>
            Außerdem müssen alle Optionen auf einer Ebene die gleichen Einstellungen hinsichtlich Offizielle erlauben haben.
        </Container>
    </MyModal>
}

function ShowBookingsOfOption({offer_ID}) {
    const [bookings, setBookings] = useState([]);
    const [{error, loading, success}, setStatusVar] = useState({});
    const loadBooking = () => maxiGet("/registration/per_offer/persons/" + offer_ID, {setStatusVar}).then(({bookings}) => setBookings(bookings));

    useMemo(() => loadBooking(), [offer_ID])
    const anyTeam = bookings.some(a => !!a.registered_person?.teamName);
    return <LightContainer name={"Buchungen"}>
        <Status type={"error"} text={error}/>
        <MyReactTable
            data={bookings}
            loading={loading}
            defaultSorted={[{id: "person.fullname"}]}
            columns={[
                {
                    Header: "Person",
                    accessor: "person.fullname",

                    filterable: true,
                },
                {
                    Header: "Mannschaft",
                    show: anyTeam,
                    accessor: "registered_person.teamName",
                    filterable: true,
                },
                {
                    Header: "Registrant",
                    accessor: "owner.fullname",
                    filterable: true,
                },
                {
                    Header: "Kosten",
                    accessor: "registered_person_offer.price",
                    filterable: true,
                    Cell: ({value}) => Consts.moneyMax(value / 100),
                },

                /* {
                     Header: "Abgesendet",
                     accessor: data => data.registration.timeFinalized ? dateFormatTime(new Date(data.registration.timeFinalized)) : "nicht abgeschickt",
                     id: "timeFinalized",
                     filterable: true,
                     //Cell: ({value}) => value ? dateFormatTime(new Date(value)) : "nicht abgeschickt"
                 },*/
                {

                    Header: "Abgesendet",
                    accessor: "registration.timeFinalized",//data => data.registration.timeFinalized ? dateFormatTime(new Date(data.registration.timeFinalized)) : "nicht abgeschickt",
                    filterable: true,
                    Cell: ({value}) => value ? dateFormatTimeShort(new Date(value)) : "nicht abgeschickt",
                    maxWidth: 150,

                },
            ]
            }
        />
    </LightContainer>
}
