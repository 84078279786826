import React, {useMemo, useState} from "react";
import {DateInput, EnumInput, MyReactTable, SelectfieldInput} from "../core/input_fields";
import {maxiPost} from "../core/maxios";
import {Container, MaxBtn} from "../core/components";
import {FormContextWrapper} from "../core/form_context";
import Status from "../core/status";
import {dateFormatDateInline} from "../core/dateFuncs";
import {FaDownload} from "react-icons/all";
import {downloadAsExcel} from "../core/download";
import {encodeGetParams} from "../core/helpers";
import {PersonEditWrapper} from "../user/personHelpers";
import {Link} from "react-router-dom";
import {EventKind, SectorType, SectorTypeLicence, SectorTypeMap} from "../core/enums";

export default function AluminList() {
    const [{loading, error}, setStatusVar] = useState({});
    const [alumni, setAlumni] = useState([]);
    const [state, setState] = useState({filter: {}});
    const {filter} = state;

    const load = (excel = false) => maxiPost("/event/alumni?" + encodeGetParams({excel}), filter, {setStatusVar})

    useMemo(() => load().then(setAlumni), [JSON.stringify(state)])


    return <Container name={"Absolventen-Liste"}>
        <Status type={"error"} text={error}/>
        <FormContextWrapper value={{state, setState}}>
            <SelectfieldInput name={"Art"} inline tag={"filter_kind"} type={"reactselect"} selectives={EventKind}/>    &nbsp;
            &nbsp;
            &nbsp;
            <EnumInput name={"Sparte"} tag={"filter_sector"} type="reactselect" selectives={SectorType.filter(a => a.value !== SectorTypeMap.t10)}/>

            &nbsp;
            &nbsp;
            &nbsp;
            <label><span>Datum</span></label><DateInput tag={"filter_start"} maxDate={filter.end}/> - <DateInput tag={"filter_end"} minDate={filter.start}/>

        </FormContextWrapper>
        <MaxBtn onClick={() => {
            load(true).then(a => downloadAsExcel(a.content, "Absolventen.xlsx"))
        }}><FaDownload/> als Excel herunterladen</MaxBtn>
        <MyReactTable
            data={alumni}
            columns={[
                {
                    Header: "Veranstaltung",
                    accessor: "event.name",
                    filterable: true,
                    Cell: ({original, value}) => <Link to={`/veranstaltungen/${original.event.ID}`}>{value}</Link>
                },
                {
                    Header: "Ende",
                    accessor: "event.eventEnd",
                    maxWidth: 120,
                    filterable: true,
                    Cell: ({value}) => value ? dateFormatDateInline(new Date(value)) : "",
                },
                {
                    Header: "Name",
                    accessor: "person.fullname",
                    filterable: true,
                    Cell: ({original, value}) => <PersonEditWrapper personLight={original.person} reload={() => load().then(setAlumni)}><em>{value}</em></PersonEditWrapper>
                },
                {
                    Header: "Geburtsdatum",
                    accessor: "person.dateOfBirth",
                    maxWidth: 130,
                    filterable: true,
                    Cell: ({value}) => value ? dateFormatDateInline(new Date(value)) : "",
                },
                {
                    Header: "Geschlecht",
                    id: "sex",
                    accessor: original => ["W", "M"][original.person.sex],
                    filterable: true,
                    maxWidth: 60,
                },
                {
                    Header: "Vereine",
                    accessor: "person.clubs",
                    filterable: true,
                }
            ]}/>
    </Container>
}
