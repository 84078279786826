import React, {useState} from "react";

import {Loader, MaxBtn} from "../core/components";
import {CheckboxInput, DateTimeInput, EnumInput, InputContainer, LightContainer, SelectfieldInput, TextfieldInput} from "../core/input_fields";
import {FormContextWrapper} from "../core/form_context";
import {maxiPost} from "../core/maxios";
import Status from "../core/status";
import "./style.sass"
import UserHistory from "../user/userHistory";
import {BookingCodes, EventKindMap, LicenceKind, LicenceKindRequired, OfferType, OfferTypeMap} from "../core/enums";

const fields = {
    type: "Art",
    description: "Bezeichnung",
    price: "Preis",
    discountedPrice: "Preis reduziert",
    onlyOfficials: "Nur Offizielle?",
    bookingCode: "Buchungscode",
    requiredLicence: "Erforderliche Turnsport-Austria-Lizenz",
    lessons: "Unterrichtseinheiten",
    //bookingEnd: "Buchbar bis",
};


export default function OfferEdit({offer,event, reload, close}) {
    const [{error, loading, success}, setStatusVar] = useState({});
    const [state, setState] = useState({offer})
    const handleSubmit = () => {
        !loading && maxiPost("/event/offer", state.offer, {setStatusVar})
            .then(() => {
                reload()
                close()
            })
    }

    return <LightContainer name={"Option bearbeiten"}>
        <FormContextWrapper value={{state, setState}} onSubmit={handleSubmit}>
            <Status type={"error"} text={error}/>
            {Object.entries(fields).map(([f, fieldName], i) => {
                switch (f) {
                    case "onlyOfficials":
                        return <>
                            <SelectfieldInput name={fieldName} tag={"offer_" + f} selectives={[[0, "beide"], [1, "nur Offizielle"], [2, "nur nicht Offizielle"]]}/>
                            <br/>
                        </>;
                    case "type":
                        return <>
                            <EnumInput name={fieldName} tag={"offer_" + f} type="reactselect" selectives={OfferType.filter(o => !!offer.parent_ID || o.value !== OfferTypeMap.selection)}/>
                            <br/>
                        </>;
                    case "bookingCode":
                        return <>
                            <br/>
                            <EnumInput name={fieldName} tag={"offer_" + f} type="reactselect" selectives={BookingCodes}/>
                            <br/>
                        </>;
                    case "lessons":
                        if (![EventKindMap.fortbildung, EventKindMap.ausbildung, EventKindMap.ausbildungwr].includes(event.kind?.value)) {
                            return null;
                        }
                        return <div style={{display: "inline-block"}}>
                            <TextfieldInput name={fieldName} tag={"offer_" + f} ph={8} style={{width: "40px", marginRight: 0}}/>&nbsp;
                            UE zu je <SelectfieldInput tag={"offer_minutesPerLesson"} type={"reactselect"} inline width={"120px"} noLabel selectives={[45, 50, 60].map(a => ({value: a, label: a + " min"}))}/>
                            <br/>
                        </div>;
                    case "requiredLicence":
                        return <>
                            <br/>
                            <EnumInput name={fieldName}  tag={"offer_" + f} type="reactselect" isClearable width={410} selectives={LicenceKindRequired}/>
                            <br/>
                        </>;
                    default:
                        if (f.endsWith("Start") || f.endsWith("End")) {
                            return <InputContainer>
                                <DateTimeInput name={fieldName} midNight tag={"offer_" + f}/>
                                {i % 2 === 0 && <br/>}
                            </InputContainer>;
                        }
                        return <>
                            <TextfieldInput class={"form-control "} style={{width: "500px"}} name={fieldName} tag={"offer_" + f}/>
                            <br/>
                        </>;
                }

            })}
            <br/>
            <MaxBtn>Speichern</MaxBtn><Loader loading={loading}/>
        </FormContextWrapper>
        <MaxBtn onClick={() => {
            if (window.confirm("wirklich löschen?")) {
                maxiPost("/event/offer/delete", {ID: offer.ID}, {setStatusVar}).then(reload)
            }
        }}>
            löschen
        </MaxBtn>
        <br/>
        {
            offer.ID && <LightContainer name={"Veränderungen"}>
                <UserHistory userID={offer.ID} personType={"offer"}/>
            </LightContainer>
        }
    </LightContainer>


}
