import React, {FunctionComponent, useState} from "react";
import {MyModal} from "./input_fields";
import {FaInfoCircle} from "react-icons/fa";
import {Link} from "react-router-dom";

type _ContainerProps = {
    ID?: string,
    name: string,
    addName?: JSX.Element,
    style?: any,
    mutable?: boolean,
    hideHeading?: boolean,
    broad?: boolean,
    className?: string,
    center?: boolean
}
const makeIdFromHeading = (name: string) => name.toLowerCase().replace(/\s/g, "_");


const Container: FunctionComponent<_ContainerProps> = ({ID, name, addName, style, mutable = false, hideHeading = false, broad = false, children, className, center = false}) => {
    const visible = true;
    return (
        <div id={ID ? ID : (name !== undefined && name !== "" ? makeIdFromHeading(name) : "")}
             className={"reactContainer centercontent " + (broad ? "broad " : "") + (center ? " center " : "") + className}
             style={style}
        >
            {name !== undefined && name !== "" && !hideHeading &&
            <h1>
                {mutable && <><i
                    className={"fa fa-angle-" + (visible ? "down" : "right")}/>&nbsp; </>}
                {name}
                {addName}
            </h1>
            }
            {children}
            <br/>
        </div>
    )
};
type _SwitchProps = {
    children: any,
    text: string,
    defaultShow: boolean
}

function MySwitch({children, text, defaultShow = false}: _SwitchProps) {
    const [noteWanted, setNoteWanted] = useState(defaultShow);

    if (noteWanted) {
        return children
    }
    return <em onClick={() => setNoteWanted(true)}>{text}</em>
}

type _CheckBoxProps = {
    tag: string,
    name: string,
    noLabel?: boolean,
    labelWidth?: number,

}
/*
work in progress
function CheckboxInput({tag, name, noLabel, labelWidth}: _CheckBoxProps) {
    const state = useContext(FormContext);
    let checked: string;
    const tagSplit = tag.split("_");
    if (tag.length === 2) {
        checked = state[tagSplit[0]] !== undefined && state[tagSplit[0]][tag[1]] !== undefined && state[tagSplit[0]][tag[1]] ? "checked" : ""
    } else {
        checked = state[tagSplit[0]] !== undefined && state[tagSplit[0]] !== undefined && state[tagSplit[0]] ? "checked" : ""
    }
    return <label className={"switchWrapper"}>
        {noLabel === undefined &&
        <span style={!!labelWidth ? {width: labelWidth.toString() + "px"} : {}}>
                    {name}
            </span>
        }
        <label className={"switch"}>
            <input type="checkbox" name={tag} checked={checked}/>
            <span className="slider"/>
        </label>
    </label>
}
*/
type _DefaultChildrenProp = {
    children: any,
}

function InfoTooltip({children}: _DefaultChildrenProp) {
    return <MyModal trigger={<FaInfoCircle/>}>
        <div>
            {children}
        </div>
    </MyModal>
}

type _LoadingProps = {
    loading: boolean,
    block?: boolean
}
const Loader = ({loading, block}: _LoadingProps) => loading ? <img style={block ? {display: "block"} : {}} width={20} height={20} alt={"loader"} src="https://uid-suche.eu/src/img/ajax-loader-fff.gif"/> : null;

type _MaxBtnProps = {
    style?: any,
    children: any,
    className?: string,
    onClick?: any,
}
const MaxBtn = ({
                    style = {}, children, className = "", onClick = () => {
    }
                }: _MaxBtnProps) => <button style={style} onClick={onClick} className={"maxbtn " + (className || "")}>{children}</button>;

type _MaxLinkProps = {
    to: string,
    style?: any,
    children: any,
    className?: string,
    onClick?: any,
}
const MaxLink = ({
                     to, style = {}, children, className = "", onClick = () => {
    }
                 }: _MaxLinkProps) => <Link to={to} style={style} onClick={onClick} className={"maxbtn " + (className || "")}>{children}</Link>;

const BlankLink = ({children}: _DefaultChildrenProp) => <a href={children} target={"_blank"} rel={"noopener noreferrer"}>{children}</a>;

type _GravatarProps = {
    hash: string,
    size: number,
}
const Gravatar = ({hash, size = 100}: _GravatarProps) => <img className="gravatar" src={`https://www.gravatar.com/avatar/${hash}?s=${size}`} alt={"Profilfoto"}/>;

export {Container, InfoTooltip, MySwitch, Loader, MaxBtn, BlankLink, MaxLink, Gravatar}

