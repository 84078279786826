import React from 'react';
import {UserContext} from "./UserContext";
import {SelectfieldInput, TextfieldInput} from "../core/input_fields";
import {apiGet} from "../core/api";
import {FormContextWrapper} from "../core/form_context";
import Consts from "../core/consts";
import {countriesConverter} from "../core/helpers";


export default class ChangeAddressExt extends React.Component {
    static contextType = UserContext;


    constructor(props) {
        super(props);
        const {child} = this.props;
        this.state = {
            search: {
                country: child.country || "AT",
                postalCode: !!child.postcode ? child.postcode.toString() : "",
                route: child.street || "",
                locality: child.city || "",
                houseNumber: child.streetNumber || "",
                houseNumberAdd: child.streetNumberAdd || "",
            },
            loading: false,
            results: [],
            localities: !!child.city ? [child.city] : [],
            houseNumbers: !!child.streetNumber ? [child.streetNumber] : [],
            streets: !!child.street ? [child.street] : [],
            open: false,
            houseNumbersInitiallyLoaded: false,
        };
        this.apiGet = apiGet.bind(this);
        this.setState = this.setState.bind(this);
    }


    getAddressComponents = (a) => {
        return {
            street: a.route,
            postcode: a.postalCode,
            streetNumber: a.houseNumber,
            streetNumberAdd: a.houseNumberAdd,
            city: a.locality,
            country: a.country,
        }

    };
    afterUpdate = (p, c, val) => {
        if (val === undefined) {
            val = this.state.search
        }
        if (val.country === "AT") {
            if (c === "postalCode" || c === "initial") {
                if (val.postalCode !== undefined && val.postalCode.length === 4 && this.state.streets.length <= 1) {
                    this.apiGet("/user/address/search_plz?plz=" + val.postalCode, resp => {
                        const {plzs, streets} = resp.aggregations;
                        this.setState({localities: plzs.buckets.map(a => a.key), search: {...this.state.search, locality: plzs.buckets.length > 0 ? plzs.buckets[0].key : ""}, streets: streets.buckets.map(a => a.key)})
                    })
                    //*/
                } else if ((!!val.locality || !!val.route) && this.state.streets.length > 1 && p !== "menuOpen") {
                    window.setTimeout(() => {
                        this.setState({search: {...this.state.search, locality: "", route: ""}, localities: [], streets: []})
                    }, 100)
                }
            }
            if (c === "initial" || (c === "route" && val.route !== undefined && val.route.length > 0) || (c === "houseNumberPreSelect" && !this.state.houseNumbersInitiallyLoaded)) {
                if (!this.state.houseNumbersInitiallyLoaded) {
                    this.setState({houseNumbersInitiallyLoaded: true})
                }
                this.apiGet("/user/address/search_number?plz=" + val.postalCode + "&street=" + val.route, resp => this.setState({houseNumbers: resp.aggregations.hnrs.buckets.map(a => a.key)}))
            }
            if (c === "route" && !!val.houseNumber && p !== "menuOpen") {
                window.setTimeout(() => this.setState({search: {...this.state.search, houseNumber: ""}}), 100)
            }
            if ((c === "houseNumber" || c === "houseNumberAdd") && !!val.houseNumber) {
                this.props.changeAddress(this.getAddressComponents(val))
            }
        } else {
            this.props.changeAddress(this.getAddressComponents(val))
        }

    };

    render() {
        const {search} = this.state;
        const SelectOrText = (props) => (search?.country === "AT" ? <SelectfieldInput {...props}/> : <TextfieldInput {...{...props, style: {width: props.width, marginRight: 0}}}/>)
        return <div>
            <FormContextWrapper
                value={{state: this.state, setState: this.setState, updateState: (a, b, c) => this.setState({[a]: {...this.state[a], [b]: c}}, () => this.afterUpdate(a, b))}}
                onSubmit={e => {
                    e.preventDefault();
                    this.search()
                }}

                afterUpdate={this.afterUpdate}
            >
                <label>
                    <span>Adresse{this.props.required ? "*" : ""}</span>
                </label>
                <div style={{display: "inline-block", verticalAlign: "top"}}>
                    <SelectfieldInput name={""} inline ph={"Land"} type={"reactselect"} width={"150px"} noLabel={true} tag={"search_country"}
                                      selectives={Consts.countries.map(a => ({label: countriesConverter.getName(a, "de"), value: a}))}/>
                    &nbsp;
                    &nbsp;
                    {/*<IntegrationAutosuggest name={"postalCode"} tag={"search_postalCode"}/>*/}
                    <TextfieldInput name={"postalCode"} tag={"search_postalCode"} noLabel ph={"PLZ"} style={{width: "80px", marginRight: "12px"}}/>


                    {search?.country === "AT" ? <>
                        <SelectfieldInput name={""} noLabel={true} tag={"search_locality"} width={"223px"} inline ph={"Ort"} type={"reactselect"}
                                          onMenuOpen={() => this.afterUpdate("menuOpen", "postalCode")} noOptionsMessage={() => "zuerst PLZ"}
                                          selectives={this.state.localities.map(s => ({label: s, value: s}))}/>
                        <br/>
                        <SelectfieldInput name={""} noLabel={true} tag={"search_route"} width={search?.country === "AT" ? "277px" : "243px"} type={"reactselect"} inline ph={"Straße"}
                                          onMenuOpen={() => this.afterUpdate("menuOpen", "postalCode")} noOptionsMessage={() => "zuerst PLZ"}
                                          selectives={this.state.streets.sort((a, b) => a < b ? -1 : 1).map(s => ({label: s, value: s}))}/>

                        &nbsp;
                        &nbsp;

                        <SelectfieldInput name={""} noLabel={true} tag={"search_houseNumber"} type={"reactselect"} inline ph={"HNr"} width={"100px"}
                                          onMenuOpen={() => this.afterUpdate("menuOpen", "houseNumberPreSelect")} noOptionsMessage={() => "zuerst Straße"}
                                          selectives={this.state.houseNumbers.sort((a, b) => parseInt(a) - parseInt(b)).map(s => ({label: s, value: s}))}/>
                    </> : <>
                        <TextfieldInput name={""} noLabel={true} tag={"search_locality"} style={{width: "223px", marginRight: 0}} inline ph={"Ort"} type={"reactselect"}
                                        onMenuOpen={() => this.afterUpdate("menuOpen", "postalCode")} noOptionsMessage={() => "zuerst PLZ"}
                                        selectives={this.state.localities.map(s => ({label: s, value: s}))}/>
                        <br/>
                        <TextfieldInput name={""} noLabel={true} tag={"search_route"} style={{width: "243px", marginRight: 0}} type={"reactselect"} inline ph={"Straße"}
                                        onMenuOpen={() => this.afterUpdate("menuOpen", "postalCode")} noOptionsMessage={() => "zuerst PLZ"}
                                        selectives={this.state.streets.map(s => ({label: s, value: s}))}/>

                        &nbsp;
                        &nbsp;

                        <TextfieldInput name={""} noLabel={true} tag={"search_houseNumber"} type={"reactselect"} inline ph={"HNr"} style={{width: "100px", marginRight: 0}}
                                        onMenuOpen={() => this.afterUpdate("menuOpen", "houseNumberPreSelect")} noOptionsMessage={() => "zuerst Straße"}
                                        selectives={this.state.houseNumbers.sort((a, b) => parseInt(a) - parseInt(b)).map(s => ({label: s, value: s}))}/>
                    </>
                    }
                    &nbsp;
                    &nbsp;
                    <TextfieldInput name={"Tür / Stiege"} noLabel tag={"search_houseNumberAdd"} style={{width: "76px"}} ph={"Tür / Stiege"}/>
                </div>
                <br/>


            </FormContextWrapper>
        </div>

    }
}
