import axios from "axios";
import Consts from "./consts";

type AnyVoidFunc = (arg: any) => {};

/*
// legacy:
interface Maxios {
    url: string,
    data?: any,
    noApi?: boolean,
    noErrorSet?: boolean,
    success: AnyVoidFunc,
    setError?: AnyVoidFunc,
}
*/

interface MaxiosOptions {
    noApi?: boolean,
    noErrorSet?: boolean,
    setError?: AnyVoidFunc,
    setStatusVar?: any,
    responseType?: "json" | "arraybuffer" | "blob" | "document" | "text" | "stream" | undefined,
    fields?: Array<string>,
}

const handleErrorTemplate = (setStatusVar: any, setError: any, noErrorSet: boolean, reject: any) => {
    return (error: string) => {
        setStatusVar && setStatusVar((old: any) => ({
                ...old,
                error,
                status: "",
                loading: false,
            })
        );
        if (!noErrorSet && setError !== undefined) {
            setError(error)
        }
        reject(error)
    }
}

function maxiTemplate(method: any) {
    return async function (url: string, options: MaxiosOptions = {}): Promise<any> {
        const {noApi, noErrorSet, setError, setStatusVar, responseType, fields} = options;
        setStatusVar && setStatusVar((a: any) => ({...a, loading: true}))
        return new Promise((resolve, reject) => {
            const handleError = handleErrorTemplate(setStatusVar, setError, !!noErrorSet, reject);
            method((!noApi ? Consts.API_PREFIX : "") + url + ((fields || []).length > 0 ? (url.includes("?") ? "&" : "?") + "fields=" + (fields || []).join(",") : ""), {
                headers: {
                    'Content-Type': 'application/json',
                },
                responseType: responseType,
            })
                //@ts-ignore
                .then(response => {

                    if (response.data.status === "error") {
                        handleError(response.data.message)
                    } else {
                        setStatusVar && setStatusVar((old: any) => ({
                            ...old,
                            ...(noErrorSet ? {} : {error: ""}),
                            loading: false,
                        }));
                        resolve(response.data)
                    }

                })
                //@ts-ignore
                .catch(error => handleError(error.toString()));
        })
    }
}

async function maxiPost(url: string, data: any, options: MaxiosOptions = {}): Promise<any> {
    const {noApi, noErrorSet, setError, setStatusVar} = options;
    setStatusVar && setStatusVar((a: any) => ({...a, loading: true}))
    return new Promise((resolve, reject) => {
        const handleError = handleErrorTemplate(setStatusVar, setError, !!noErrorSet, reject);
        axios.post((!noApi ? Consts.API_PREFIX : "") + url, data, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(response => {
                if (response.data.status === "error") {
                    handleError(response.data.message)
                } else {
                    setStatusVar && setStatusVar((old: any) => ({
                        ...old,
                        ...(noErrorSet ? {} : {error: ""}),
                        loading: false,
                    }));
                    resolve(response.data)
                }

            })
            .catch(error => handleError(error.toString()));
    })
}

const maxiGet = maxiTemplate(axios.get);
const maxiDelete = maxiTemplate(axios.delete);

export {maxiGet, maxiPost, maxiDelete}
