import React from "react";

export const countriesConverter = require("i18n-iso-countries");
countriesConverter.registerLocale(require("i18n-iso-countries/langs/de.json"));

export const embrace = (t) => t > 0 ? `(${t})` : "";
//const t0 = new Date() - (new Date().getTime() % (3600 * 1000));

export const encodeGetParams = p => Object.entries(p).map(kv => kv.map(encodeURIComponent).join("=")).join("&");

export function AddressPrinter({street, streetNumber, postcode, city, country,}) {
    return `${street} ${streetNumber}, ${postcode} ${city}${country !== "AT" ? ", " + countriesConverter.getName(country, "de") : ""} `
}

export function AddressPrinterFull({recipient, recipientAddition, street, streetNumber, streetNumberAdd, postcode, city, country,}) {
    if (!streetNumber>0){
        return null;
    }
    return <div>
        {recipient && <b>{recipient}<br/></b>}
        {recipientAddition && <>{recipientAddition}<br/></>}
        {street} {streetNumber}{!!streetNumberAdd && "/" + streetNumberAdd} <br/>
        {postcode} {city}
        {country !== "AT" ? <><br/>{countriesConverter.getName(country, "de")}</> : ""}
    </div>
}
