import React, {useContext, useMemo, useRef, useState} from "react";

import {Container, Loader, MaxBtn} from "../core/components";
import {maxiGet, maxiPost} from "../core/maxios";
import Status from "../core/status";
import {dateFormatDateInline} from "../core/dateFuncs";
import "./style.sass"
import {CheckboxInput, LightContainer, MiniBtn, MyModal} from "../core/input_fields";
import {Link} from "react-router-dom";
import BillingAddressList from "./billingAddressList";
import {AddressPrinterFull} from "../core/helpers";
import {PersonEditWrapper, RegisteredPersonsList} from "./registrationPersonTeamMod";
import OffersShow from "./offersShow";
import {RegistrationStatusLabelMap, RegistrationStatusMap} from "../core/enums";
import {UserContext} from "../user/UserContext";
import {FormContextWrapper} from "../core/form_context";
import UserHistory from "../user/userHistory";
import {FaCheck} from "react-icons/fa";
import Consts from "../core/consts";


export default function RegistrationView({match}) {
    const user = useContext(UserContext)?.user || {};

    const registration_ID = match?.params?.registration_ID;
    const [event, setEvent] = useState(null);
    const [registration, setRegistration] = useState(null);
    const [offers, setOffers] = useState([]);
    const [registeredPersons, setRegisteredPersons] = useState([]);
    const [billingAddress, setBillingAddress] = useState([]);
    const [{error, loading, success}, setStatusVar] = useState({loading: true});
    const loadRegistration = () => {
        maxiGet(`/registration/${registration_ID}`, {setStatusVar}).then(({event, offers, registeredPersons, registration, billingAddress}) => {
            setOffers(offers)
            setRegisteredPersons(registeredPersons)
            setRegistration(registration)
            setBillingAddress(billingAddress)
            setEvent({
                ...event,
                eventStart: new Date(event.eventStart),
                eventEnd: new Date(event.eventEnd),
                bookingStart: new Date(event.bookingStart),
                bookingEnd: new Date(event.bookingEnd),
                prebookingEnd: new Date(event.prebookingEnd),
            })
        })
    }
    useMemo(() => {
        loadRegistration()
    }, [])


    return <Container name={"Veranstaltung "} addName={event?.name}>

        <Status type={"error"} text={error}/>
        <Loader loading={loading}/>
        {
            event && <EventPrint event={event}/>
        }

        {
            registration && event && <>
                {
                    registration.status.value === RegistrationStatusMap.finalized && <>
                        <br/>
                        Diese Registrierung wurde bereits abgeschickt. Um Sie zu bearbeiten klicke unten auf <i>Wieder öffnen</i>!
                    </>
                }
                <RegisteredPersonsList {...{event, registeredPersons, registration, loadRegistration}}/>
                <OffersShow {...{registration, registeredPersons, offers, event}}/>
                <BillingAddressSelect {...{registration, reload: loadRegistration, billingAddress}}/>
                <Finalize registration={registration} reload={loadRegistration}/>
                {
                    user.role === 100 &&
                    <UserHistory userID={registration_ID} personType={"registration"}/>
                }
            </>
        }
    </Container>
}

function Finalize({registration, reload}) {
    const [{error, loading, success}, setStatusVar] = useState({});
    const [state, setState] = useState({});
    const user = useContext(UserContext)?.user || {}

    const [personsLicenceChecks, setPersonsLicenceChecks] = useState([]);

    const finalize = (force) => {
        if (loading) {
            alert("Diese Anmeldung wird gerade verarbeitet.")
            return
        }
        if (state.dataprotection !== 1) {
            alert("Bitte die Hinweise lesen und zur Kenntnis nehmen.")
            return
        }
        setStatusVar(e => ({...e, loading: true}))
        maxiPost(`/finalize`, {registration_ID: registration.ID, force}, {setStatusVar}).then(resp => {
            if (resp.error === "licence_validation_failed") {
                setStatusVar({error: "Bei zumindest einer Person ist keine gültige Lizenz vorhanden."})
                setPersonsLicenceChecks(resp.failures)
            } else {
                setStatusVar(o => ({...o, success: "Die Meldung wurde erfolgreich abgeschickt und du solltest ein E-Mail mit einer Bestätigung erhalten haben.."}))
            }
            //reload() // would reload such that the success message is not visible anymore
        })
    }
    return <>
        {
            registration.status.value === RegistrationStatusMap.draft &&
            <LightContainer name={"Fertigstellen"}>

                <p>Ich bestätige hiermit rechtsverbindlich, dass ich für alle von mir angemeldeten Personen verbindlich berechtigt wurde, diese Anmeldung in ihrem Auftrag und Namen durchzuführen. Sollte ich nur mich selbst anmelden, gelten alle nachfolgenden Bestimmungen für mich selbst.</p>
                <p>Ich bestätige hiermit, dass ich alle von mir angemeldeten Personen rechtsverbindlich zur Einhaltung aller Bestimmungen der Sport Austria verpflichtet habe, die im Zusammenhang mit der Veranstaltung stehen, für die diese Anmeldung erfolgt. </p>
                <p>Sport Austria wird von mir und von den von mir angemeldeten Personen im Zusammenhang mit der Veranstaltung, für die diese Anmeldung erfolgt, schad- und klaglos gehalten. </p>
                <p>Ich bestätige hiermit rechtsverbindlich, dass ich von allen von mir angemeldeten Personen die rechtsverbindliche Bestätigung eingeholt habe, dass ihre bei der Anmeldung anzugebenden Daten zur Gänze und/oder in modifizierter Form von Sport Austria ohne Befristung gespeichert,
                    verarbeitet, an Sport-Austria-Auftragsverarbeiter (z.B. für die EDV-Wettkampfauswertung) weitergegeben und in beliebiger Form veröffentlicht werden dürfen. </p>
                <p>Ein Widerruf und/oder eine Nichtdurchführung einer oder aller der o.a. Bestätigungen ist jederzeit vor der Veranstaltung für/von alle/n oder einzelne/n von mir angemeldeten Personen möglich. Widerrufe sind per Email an office@turnsport.at zu richten. Ich nehme allerdings zur
                    Kenntnis, dass ein solcher Widerruf und/oder eine Nichtdurchführung einer Bestätigung bedeutet, dass eine Teilnahme an der Veranstaltung nicht möglich sein kann. Durch die Anmeldung fällig gewordenes Nenngeld verbleibt im Falle eines Widerrufs und somit einer Nichtteilnahme
                    fällig bzw. wird nicht rückerstattet.</p>
                <p>Ich bestätige hiermit rechtsverbindlich, von allen von mir angemeldeten Personen das uneingeschränkte und unwiderrufliche Einverständnis eingeholt zu haben, bei der Veranstaltung gefilmt, fotografiert und/oder tonaufgezeichnet zu werden – sowie, dass sich daraus für mich
                    und/oder die angemeldeten Personen keine Rechte (z.B. Entgelt) ableiten.</p>
                <p>Ich bestätige weiters, das uneingeschränkte und unwiderrufliche Einverständnis aller von mir angemeldeten Personen eingeholt zu haben, dass diese o.a. Filme, Fotos und/oder Tonaufnahmen zur für die Angemeldeten entgeltfreien Live- oder beliebig zeitversetzten Veröffentlichung
                    mit oder ohne Namensnennung durch Sport Austria, dessen Landesverbände und Vereine sowie kooperierende Medien und Partner unbefristet verwendet und ohne zeitliche Beschränkung gespeichert sowie mit oder ohne Namensnennung veröffentlicht werden können. </p>
                <p>Ich bestätige insbesondere, dass Sport Austria berechtigt ist, bei der Veranstaltung erstellte Fotos, Videos und Tonaufzeichnungen der von mir angemeldeten Personen im Sinne seines gemeinnützigen Zweckes zur Förderung des Turnsports kommerziell zu verwerten und/oder andere
                    Personen/Organisationen/Unternehmen damit zu beauftragen. </p>

                <FormContextWrapper value={{state, setState}}>
                    <div style={{paddingBottom: "", float: "left", paddingRight: "10px"}}>
                        <CheckboxInput name={"akzeptiere"} tag={"dataprotection"} noLabel/>
                    </div>
                </FormContextWrapper>
                <div>
                    <br/>
                    Hiermit bestätige ich, dass ich alle 8 oben angeführten Absätze zur Kenntnis genommen habe.
                </div>
                <br/>
                <br/>
                <Status type={"success"} text={success}/>
                <Status type={"error"} text={error}/>
                <Loader loading={loading}/>
                {
                    personsLicenceChecks.length > 0 && <PersonLicenceCheck {...{personsLicenceChecks}}/>
                }
                <MaxBtn onClick={() => finalize(false)}>Meldung abschicken</MaxBtn>
                {
                    personsLicenceChecks.length > 0 && personsLicenceChecks.every(({type}) => type === "missing_licence_number") && <>&nbsp;
                        <MaxBtn onClick={() => window.confirm("Wenn eine Person ohne Turnsport-Austria-Lizenznummer angemeldet wird, kann dieser der Besuch der Veranstaltung nicht zur Verlängerung einer Turnsport-Austria-Lizenz angerechnet werden.") && finalize(true)}>
                            Meldung abschicken ohne Turnsport-Austria-Lizenznummer(n)
                        </MaxBtn>
                    </>
                }

                <br/><br/>

            </LightContainer>
        }
        {
            user.role === 100 && registration.status.value === RegistrationStatusMap.finalized && <MaxBtn onClick={() => {
                maxiPost(`/finalize/reopen`, {registration_ID: registration.ID}).then(() => {
                    reload()
                })
            }}>Wieder öffnen</MaxBtn>
        }

    </>
}

function PersonLicenceCheck({personsLicenceChecks}) {
    return <>
        <table>
            <tr>
                <th>Person</th>
                <th>Fehler</th>
            </tr>
            {
                personsLicenceChecks.map(({error, type, person}) => <tr>
                    <td><PersonEditWrapper personLight={person}><em>{person.fullname}</em></PersonEditWrapper></td>
                    <td>{type === "missing_licence_number" ? <PersonAddLicenceNumber person={person}/> : error}</td>
                </tr>)
            }
        </table>
    </>
}

function PersonAddLicenceNumber({person}) {
    const [{error, success}, setStatusVar] = useState({});

    const ref = useRef();
    const submit = () => maxiPost("/user/licence_number", {person_ID: person.ID, licenceNumber: ref.current.value}, {setStatusVar}).then(() => setStatusVar({success: true}));
    if (success) {
        return <FaCheck/>;
    }
    return <>
        <Status type={"error"} text={error}/>
        <input type={"text"} style={{width: "100px"}} placeholder={Consts.placeHolders["licenceNumber"]} ref={ref}/>
        <MiniBtn onClick={submit}>Speichern</MiniBtn>
    </>
}

function EventPrint({event}) {
    const {city, eventStart, eventEnd, description} = event

    return <div>
        {city}, {dateFormatDateInline(eventStart)} bis {dateFormatDateInline(eventEnd)}<br/>
        <Link to={"/veranstaltungen/" + event.ID}>mehr anzeigen</Link>
    </div>
}


function BillingAddressSelect({registration, reload, billingAddress}) {
    const [{error, loading, success}, setStatusVar] = useState({});

    const handleSubmit = (ba, close) => {
        !loading && maxiPost(`/registration/${registration.ID}/change_billingAddress`, {billingAddress_ID: ba.ID}, {setStatusVar})
            .then(() => {
                reload()
                close()
            })
    }
    return <LightContainer name={"Rechnungsadresse auswählen"}>
        <div>
            {billingAddress && <AddressPrinterFull {...billingAddress}/>}
            {!billingAddress && <>Keine ausgewählt</>}
        </div>
        {
            registration.status.value === RegistrationStatusMap.draft && <MyModal trigger={<MaxBtn>Rechnungsadresse {billingAddress ? "ändern" : "auswählen"}</MaxBtn>}>
                {close => <>
                    <Status type={"error"} text={error}/>
                    <BillingAddressList
                        nameCell={({original, value}) => <em onClick={() => {
                            handleSubmit(original, close)
                        }}>{value} (hinzufügen)</em>}
                        error={""}
                        loadPersons={() => null}
                        match={{}}
                        loading={false}
                    />
                </>
                }
            </MyModal>
        }
    </LightContainer>
}
