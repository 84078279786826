import React, {useContext, useState} from "react";

import {InfoTooltip, Loader, MaxBtn} from "../core/components";
import {DateInput, DateTimeInput, EnumInput, InputContainer, LightContainer, MyModal, SelectfieldInput, TagsInput, TextfieldInput} from "../core/input_fields";
import {FormContextWrapper} from "../core/form_context";
import {maxiPost} from "../core/maxios";
import Status from "../core/status";
import UserHistory from "../user/userHistory";
import Consts from "../core/consts";
import {FaCheckCircle} from "react-icons/all";
import "./style.sass"
import {OfferType, OfferTypeMap, PersonType, SectorType, SectorTypeMap} from "../core/enums";
import ChangeAddressExt from "./addressExt";
import {UserContext} from "./UserContext";

const fieldsRequired = ["forename", "surname", "sex", "dateOfBirth"]

export default function PersonEdit({person, reload, close}) {
    const [{error, loading, success}, setStatusVar] = useState({});
    const [state, setState] = useState({person})
    const [emailValid, setEmailValid] = useState({person})
    const user = (useContext(UserContext) || {}).user || {}

    const handleSubmit = () => {
        !loading && maxiPost("/user", state.person, {setStatusVar})
            .then(() => {
                reload && reload()
                close && close()
            })
    }
    const checkPhone = (e) => {
        const s = e.target.name.split("_");
        const val = state[s[0]][s[1]];
        if (s[1].startsWith("phone")) {

            const newVal = Consts.phoneNormalise(val)
            if (newVal !== val) {
                setState({[s[0]]: {...state[s[0]], [s[1]]: newVal}})
                //console.log(val, newVal)
            }
        }
        if (s[1] === "email") {
            maxiPost("/user/address/email", {email: val}).then(resp => {
                if (resp.email === (state[s[0]] || {}).email) {
                    setEmailValid(resp.valid)
                }
            })
        }
    };


    return <LightContainer name={"Person bearbeiten"}>
        <Status type={"error"} text={error}/>

        <FormContextWrapper value={{state, setState}} onSubmit={handleSubmit}>
            <div>
                {["sex", "forename", "surname", "dateOfBirth", "email", "phone", "licenceNumber"]
                    .map((f, i) => {
                        switch (f) {
                            case "country":
                                return <InputContainer>
                                    <SelectfieldInput name={"Land"} tag={"person_" + f} selectives={[["AUT", "Österreich"], ["GER", "Deutschland"]]}/>
                                    {i % 2 === 0 && <br/>}
                                </InputContainer>
                            case "type":
                                return [<InputContainer>
                                    <EnumInput name={"Art"} tag={"person_" + f} type="reactselect" selectives={PersonType}/>
                                </InputContainer>, <br/>]
                            case "sex":
                                return [<InputContainer>
                                    <SelectfieldInput name={"Geschlecht*"} tag={"person_" + f} demandSelect selectives={[["0", "Weiblich"], ["1", "Männlich"]]}/>
                                </InputContainer>, <br/>]
                            case "email":
                                return <InputContainer>
                                    <TextfieldInput
                                        onBlur={checkPhone}
                                        ph={Consts.placeHolders[f]}
                                        inputBorderColor={emailValid === false ? "red" : null}
                                        name={<>{Consts.translate(f)} {emailValid === true && <FaCheckCircle/>}{emailValid === false && "(falsch)"}</>}
                                        tag={"person_" + f}
                                    />
                                    {(i % 2 === 1) && <br/>}
                                </InputContainer>
                            case "phone":
                                return <InputContainer>
                                    <TextfieldInput
                                        ph={Consts.placeHolders[f]}
                                        onBlur={checkPhone}
                                        name={Consts.translate(f)}
                                        tag={"person_" + f}
                                    />
                                    {(i % 2 === 1) && <br/>}
                                </InputContainer>
                            case "dateOfBirth":
                                return <InputContainer>
                                    <DateInput name={Consts.translate(f) + "*"} tag={"person_" + f}/>
                                    {(i % 2 === 1) && <br/>}
                                </InputContainer>
                            case "licenceNumber":
                                return <InputContainer>
                                    <TextfieldInput readOnly={person.parent_ID === null} ph={person.parent_ID === null ? "" : Consts.placeHolders[f]} name={<>
                                        {Consts.fieldTranslation[f] || f}
                                        {person.parent_ID === null &&
                                        <InfoTooltip>Die Lizenznummer kann bei eigenständigen Benutzern nicht eingetragen werden, sondern es muss das gleiche Konto verwendet werden, wie beim Beantragen der Lizenz. Bei Fragen bitte an lizenz@turnsport.at melden.</InfoTooltip>}
                                    </>} tag={"person_" + f}/>
                                    {i % 2 === 1 && <br/>}
                                </InputContainer>
                            case "postalCode":
                                return <>
                                    <TextfieldInput name={Consts.fieldTranslation[f] || f} tag={"person_" + f}/>
                                    {i % 2 === 1 && <br/>}
                                </>
                            default:
                                return <InputContainer>
                                    <TextfieldInput ph={Consts.placeHolders[f]} name={(Consts.fieldTranslation[f] || f) + (fieldsRequired.includes(f) ? "*" : "")} tag={"person_" + f}/>
                                    {i % 2 === 1 && <br/>}
                                </InputContainer>
                        }
                    })}
                <br/>
                {
                    user.role > 80 && <><SelectfieldInput name={"Berechtigung"} tag={"person_permission"} selectives={Object.entries(Consts.permissionMap)}/></>
                }
                <EnumInput name={"Sparten"} tag={"person_sectors"} multiple type="reactselect" selectives={SectorType.filter(a => [SectorTypeMap.crossSector, SectorTypeMap.gt, SectorTypeMap.gti].includes(a.value) === false)}/>

                <TagsInput multiple name={"Verein"} entity={"club"} ID={0} tag={"person_clubs"}/>
            </div>

        </FormContextWrapper>
        <ChangeAddressExt child={person} changeAddress={a => {
            setState(old => ({person: {...old.person, ...a}}))
        }}/>
        <div>
            <br/>
            <MaxBtn onClick={handleSubmit}>Speichern</MaxBtn><Loader loading={loading}/>
            <br/>
            {
                person.ID && user.role > 80 && <LightContainer name={"Veränderungen"}>
                    <UserHistory userID={person.ID} personType={"person"}/>
                </LightContainer>
            }
        </div>
    </LightContainer>


}
